import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";

import { getCourseDetails } from "redux/actions";
import SEO from "seo/SEO";
import { CourseDetailsPage } from "templates";
import { deSlugAndCapitalize, scrollToTop } from "utils/utilities";

const CourseDetails = () => {
  
  const dispatch = useDispatch();
  const { course } = useSelector(state => state.data);
  const { slug } = useParams();

  useEffect(() => {
    dispatch(getCourseDetails(slug));
    scrollToTop(window); 
  }, [dispatch, slug])

  return (
    <>
      <SEO
        title={`${course?.data?.slug === slug ?  course?.data?.title : deSlugAndCapitalize(slug)} | Vasudev College of Pharmacy Haldwani`}
        description={course?.data?.description || "Vasudev College of Pharmacy is one of the best pharmacy college in Haldwani, Nainital. We offer B.Pharma and D.Pharma courses in Haldwani, Nainital."}
        currentURL={`${process.env.REACT_APP_PUBLIC_URL}/course/${slug}`}        
      />
      <CourseDetailsPage/>
    </>
  )
}

export default CourseDetails;