import React from "react";
import { BiLinkExternal } from "react-icons/bi";

import { Box, Flex, Text } from "atoms";
import { GetLink } from "molecules";

export const LinkCard = ({
  title,
  url,
  bg="white"
}) => {
  return (
    <Box
      width="100%"
      bg={bg}
    >
      <GetLink
        url={url}
      >
        <Flex
          width="100%"
          height="100%"    
          alignItems="center"
          justifyContent="space-between"
          p="1.6rem"
          color="#707070"
          _hover={{
            color: "secondary.500"
          }}
        >
          <Text>
            {title}
          </Text>
          <Flex
            alignItems="center"
            justifyContent="center"      
            fontSize="2.4rem"
            ml="1rem"
          >
            <BiLinkExternal/>
          </Flex>
        </Flex>
      </GetLink>
    </Box>
  )
}