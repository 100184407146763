import React from "react";
import { PulseLoader } from "react-spinners"

import Box from "../Box";
import Flex from "../Flex";

export const Loader = ({ withBg }) => {
  return (
    <Box
      position="fixed"
      top="0"
      left="0"
      right="0"
      bottom="0"
      zIndex="999"    
      animation="fadeIn 0.5s;"       
    >
      <Flex 
        width="100%"
        height="100%"        
        alignItems="center"
        justifyContent="center"  
        backgroundColor="rgba(0, 0, 0, 0.6)"      
      >
        <PulseLoader
          color={"#fff"}          
        />        
      </Flex>
    </Box>
    )
}