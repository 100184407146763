import React from "react";

import { Box, Flex, Text } from "atoms";
import { GetLink } from "molecules";

export const PageHero = ({
  image,
  title,
  breadcrumbs
}) => {
  return (
    <Box
      width="100%"
      height={{xs: "38rem", md: "40rem"}}
      background="rgb(17,29,94)"
      backgroundImage={`linear-gradient(90deg, rgba(17,29,94,0.8660714285714286) 59%, rgba(227,40,69,0.4431022408963585) 100%), url(${image})`}
      backgroundPosition="center"
      backgroundRepeat="no-repeat"
      backgroundSize="cover"
    >
      <Flex
        position="absolute"
        flexDirection="column"      
        left={{xs: "0", md: "6rem"}}
        top="40%"        
        transform="translateY(-40%)"
        width={{xs: "100%", md: "unset"}}   
        justifyContent={{xs: "center", md: "unset"}}     
      >
        <Text
          as="h1"
          color="white"
          fontSize={{xs: "3.5rem", md: "5rem"}}
          fontWeight="800"
          mb="1rem"
          textAlign={{xs: "center", md: "unset"}}
        >
          {title}
        </Text>        
        <Text
          as="p"
          color="white"
          fontSize="1.5rem"
          fontWeight="600"
          mt="1rem"
          textAlign={{xs: "center", md: "unset"}}
        >
          {breadcrumbs?.map((breadcrumb, index) => (
            <Text
              key={index}
              as="span"
              color={index < breadcrumbs.length - 1 ? "white" : "secondary.500"}
              textTransform="capitalize"
            >
              <GetLink                
                url={breadcrumb.url}
              >
                {breadcrumb.title} {index < breadcrumbs.length - 1 && " / "}
              </GetLink>
            </Text>
          ))}
        </Text>
      </Flex>
    </Box>
  )
}