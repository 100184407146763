import React, { useState } from "react";

import { Box, Button, Flex, Image, Text } from "atoms";
import { GetLink } from "molecules";

export const AlbumCard = ({  
  title,
  image,
  no,
  slug
}) => {

  const [ isHovered, setIsHovered ] = useState(true);

  return (
    <Box
      width={{xs: "100%"}}
      height="32rem"
      cursor="pointer"
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(true)}
    >
      <Image
        src={image}
        alt={title}
        width="100%"
        height="100%"
        objectFit="cover"
        borderRadius="0.2rem"        
      />
      <Box
        width="90%"
        height="90%"
        position="absolute"
        top="50%"
        left="50%"
        transform="translate(-50%, -50%)"
        display={isHovered ? "block" : "none"}        
        bg="rgba(255, 255, 255, 0.7)"
        borderRadius="0.2rem"
        p="2rem"
        transition="all 0.3s ease-in-out"        
      >
        <GetLink
          url={`/album/${slug}`}              
        >
          <Flex
            flexDirection="column"
            alignItems="center"
            justifyContent="center"
            width="100%"
            height="100%"
          >            
            <Text
              as="h3"
              fontSize="1.8rem"
              fontWeight="800"
              textAlign="center"
              color="#707070"
            >
              {title}
            </Text>
            <Text
              as="p"
              fontSize="1.6rem"
              fontWeight="600"
              textAlign="center"
              color="#707070"
              mt="1rem"
            >
              {no} Photos
            </Text>
            <Button
              p="0.8rem 1.5rem"
              width="fit-content"
              mt="1.5rem"
            >
              View Images
            </Button>
          </Flex>
        </GetLink>
      </Box>
    </Box>
  )
}