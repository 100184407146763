import React, { useEffect } from "react";

import SEO from "seo/SEO";
import { GalleryPage } from "templates";
import { scrollToTop } from "utils/utilities";

const Gallery = () => {

  useEffect(() => {
    scrollToTop(window);
  }, [])

  return (
    <>
      <SEO
        title="Albums | Vasudev College of Pharmacy Haldwani"
        description="Vasudev College of Pharmacy is one of the best pharmacy college in Haldwani, Nainital. We offer B.Pharma and D.Pharma courses in Haldwani, Nainital."
        currentURL={`${process.env.REACT_APP_PUBLIC_URL}/albums`}
      />
      <GalleryPage/>
    </>
  )
}

export default Gallery;