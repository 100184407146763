import React from "react";
import { FaRegFilePdf } from "react-icons/fa";

import { Flex, Text } from "atoms";
import { GetLink } from "molecules";

export const DocumentCard = ({
  title,
  url,
  bg="white"  
}) => {
  return (
    <GetLink
      url={url}  
    >
      <Flex
        width="100%"
        height="100%"
        bg={bg}
        alignItems="center"
      >
        <Flex
          alignItems="center"
          justifyContent="center"
          height="100%"
          bg="secondary.500"
          p="1.6rem"
          fontSize="2.4rem"
          color="white"
        >
          <FaRegFilePdf/>
        </Flex>
        <Text
          as="p"
          fontSize="1.6rem"
          fontWeight="600"
          color="#707070"
          _hover={{
            color: "secondary.500"
          }}
          p="1rem"
        >
          {title}
        </Text>
      </Flex>
    </GetLink>  
  )
}