import React from "react";
import { Formik, Form } from "formik";
import { BsSendFill } from "react-icons/bs";
import { useDispatch, useSelector } from "react-redux";
import * as Yup from "yup";

import { Box, Button, Flex, Input, Text } from "atoms";
import { sendContactMail } from "redux/actions";

const validationSchema = Yup.object({  
  name: Yup.string()
    .required("Name Required")
    .max(100, "Max 100 characters allowed")
    .min(3, "Min 3 characters required"),  
  phone: Yup.string()
    .required("Phone Required"),
  subject: Yup.string()
    .required("Subject Required"),  
  message: Yup.string().max(2000, "Max 2000 characters allowed"),
});

export const ContactForm = () => {
  
  const dispatch = useDispatch();
  const { loading } = useSelector(state => state.app);

  const onSubmit = async (values, { resetForm, setSubmitting }) => {    
    const form = {
      name: values.name,
      email: values.email,
      phone: values.phone,
      subject: values.subject,
      message: values.message,      
    }      
    dispatch(sendContactMail(form));
    resetForm();
    setSubmitting(false)          
  }

  return (
    <Box
      p="3rem"
      boxShadow="0 0 20px 3px rgba(0, 0, 0, 0.05)"
      bg="white"
    >
      <Text
        fontSize="3rem"
        color="primary.500"
        fontWeight="800"
        mb="2rem"
      >
        Leave a Message
      </Text>

      <Box>
        <Formik
          initialValues={{
            name: "",            
            email: "",
            phone: "",
            subject: "",
            message: "",
          }}
          validationSchema={validationSchema}
          onSubmit={onSubmit}
        >
          {({ touched, errors }) => {
            return (
              <Form>
                <Flex                
                  flexDirection={{xs: "column", md: "row"}}
                >
                  <Box
                    width="100%"
                    mr={{md: "1rem"}}
                  >
                    <Input                  
                      name="name"
                      type="text"
                      label="Your Name"
                      placeholder="Type here"
                      id="name"                  
                      touched={touched.name}
                      errors={errors.name}                            
                      formik={true}                          
                    />  
                  </Box>
                  <Box
                    width="100%" 
                    ml={{md: "1rem"}}                   
                  >
                    <Input                  
                      name="email"
                      type="email"
                      label="Email Address"
                      placeholder="Type here"
                      id="email"                  
                      touched={touched.email}
                      errors={errors.email}                              
                      formik={true}                                               
                    />  
                  </Box>
                </Flex> 
                <Flex
                  flexDirection={{xs: "column", md: "row"}}
                >
                  <Box
                    width="100%"
                    mr={{md: "1rem"}}
                  >
                    <Input                  
                      name="phone"
                      type="text"
                      label="Phone Number"
                      placeholder="Type here"                  
                      id="phone"                  
                      touched={touched.phone}
                      errors={errors.phone}                            
                      formik={true}                
                    />
                  </Box>
                  <Box
                    width="100%"
                    ml={{md: "1rem"}}
                  >
                    <Input                  
                      name="subject"
                      type="text"
                      label="Subject"
                      placeholder="Type here"                  
                      id="subject"                  
                      touched={touched.subject}
                      errors={errors.subject}                                    
                      formik={true}                      
                    />
                  </Box>
                </Flex>
                <Box>
                  <Input                  
                    name="message"
                    type="text"
                    label="Message"
                    placeholder="Type here"                  
                    id="message"                  
                    touched={touched.message}
                    errors={errors.message}                      
                    textArea  
                    rows="6"
                    formik={true}                
                  />                                                      
                </Box>                                                                                     
                <Button    
                  variant="secondary"
                  width="fit-content"                  
                  p="1.5rem 3rem"                  
                  borderRadius="5rem"
                  type="submit"
                  fontSize="1.6rem"
                  fontWeight="500"                                    
                  loading={loading}
                > 
                  <Flex 
                    alignItems="center"
                    style={{
                      gap: "0.5rem"
                    }}
                  >
                    <Flex
                      alignItems="center"
                      justifyContent="center"                      
                      fontSize="1.6rem"                      
                    >
                      <BsSendFill/>
                    </Flex>                 
                    <Text>
                      Send Message
                    </Text>
                  </Flex>                                   
                </Button>
              </Form>
            )
          }}
        </Formik>
      </Box>
    </Box>
  )
}