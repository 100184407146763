import React from "react";
import { BsTelephoneFill } from "react-icons/bs";
import { FaLocationArrow } from "react-icons/fa";

import { Box, Grid, Flex, Text } from "atoms";

export const Contact = ({
  title,
  description,
  mobile,
  email,
  address
}) => {

  const onContactClick = (url) => {
    window.open(url, "_blank");
  }

  return (
    <Box>
      <Text
        as="h2"
        fontSize={{xs: "2.2rem", md: "3rem"}}
        color="primary.500"
        fontWeight="800"
        mb="2rem"
      >
        {title}
      </Text>
      <Text
        as="p"
        mb="2rem"
        fontSize="1.6rem"
        color="#707070"
        className="text-justify"
      >
        {description}
      </Text>

      <Grid
        gridTemplateColumns={{xs: "1fr", md: "repeat(2, 1fr)"}}
        gridGap="2.4rem"
      >
        <ContactCard
          Icon={<BsTelephoneFill/>}
          title="Contact"
        > 
          <ContactElement
            title="Mobile"
            values={mobile}
            onContactClick={onContactClick}
            prefix="tel"
          />         
          <ContactElement
            title="Email"
            values={email}
            onContactClick={onContactClick}
            prefix="mailto"
          />
        </ContactCard>
        {address && <ContactCard
          Icon={<FaLocationArrow/>}
          title="Address"
        >
          <Text
            as="span"
            color="#707070"
            fontSize="1.6rem"
            mb="1rem"
          >
            {address}
          </Text>          
        </ContactCard>}
      </Grid>
    </Box>
  )
}

const ContactCard = ({
  Icon,
  title,
  children
}) => (
  <Flex
    p="3rem"
    bg="#f3f3f3"
    textAlign="center"
    alignItems="center"
    flexDirection="column"
    minWidth="100%"
  >
    <Flex
      alignItems="center"
      justifyContent="center"
      width="6.5rem"
      height="6.5rem"
      borderRadius="50%"
      border="1px solid"
      borderColor="secondary.500"
      mb="1.5rem"
      color="secondary.500"
      fontSize="3rem"
    >
      {Icon}
    </Flex>
    <Text
      as="h4"
      fontSize="2.2rem"
      fontWeight="800"
      color="primary.500"
      mb="1.5rem"
    >
      {title}
    </Text>
    {children}
  </Flex>
)

const ContactElement = ({
  values,
  title,
  onContactClick,
  prefix
}) => (
  <Text              
    color="#707070"
    fontSize="1.6rem"
    mb="1rem"
    maxWidth="100%"    
    className="break-word"
  >
    {title}:
    {values?.map((item, index) => (
      <Text
        key={index}
        as="span"
      >
        <Text                  
          as="span"
          ml="0.5rem"
          color="primary.500"
          cursor="pointer"
          onClick={() => onContactClick(`${prefix}:${item}`)}          
          _hover={{
            textDecoration: "underline",
            color: "secondary.500"
          }}          
        >
          {item}                
        </Text>
        {index < values?.length - 1 && " | "}
      </Text>
    ))}                                               
  </Text>
)          