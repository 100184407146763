import { createRef } from "react";
import { BiLeftArrow, BiRightArrow } from "react-icons/bi";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import { Box, Button, Flex, Text } from "atoms";
import { GetLink } from "molecules";
import { getStrapiMediaUrl } from "utils/utilities";

const variants = ["secondary", "primary"]

export const HomeSlider = ({
  slides
}) => {

  const sliderRef = createRef();  

  const settings = {        
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,    
    arrows: false,
    autoplay: true,
    autoplaySpeed: 5000,
    dots: false,
  };

  return (
    <Box
      width="100%"
      bg="primary.500"
    >      
      <Box
        width="98%"
        height={{xs: "75rem", md: "64rem"}}
        mx="auto"
        borderRadius="1rem"        
      >
        <Slider
          ref={sliderRef}
          {...settings}                
        >
          {slides?.map((item) => (
            <Box 
              key={item?.id}             
              height={{xs: "75rem", md: "64rem"}}
              overFlow="hidden"
            >
              <SliderCard                
                image={getStrapiMediaUrl(item?.image?.url)}
                title={item?.title}
                description={item?.description}
                buttons={item?.buttons?.map((button, index) => ({
                  label: button?.title,
                  url: button?.url,
                  variant: variants[index%2]
                }))}
              />
            </Box>
          ))}
        </Slider>        
      </Box>

      {/* ARROWS */}
      <Flex
        position="absolute"
        top="50%"
        transform="translateY(-50%)"
        left="0.5rem"
        zIndex="1"
        alignItems="center"
        justifyContent="center"
        bg="white"
        color="primary.500"
        borderRadius="50%"
        fontSize="1.8rem"
        p="0.8rem"
        cursor="pointer"
        onClick={() => sliderRef.current.slickPrev()}
      >
        <BiLeftArrow
          style={{ transform: "translateX(-0.1rem)" }}
        />
      </Flex>
      <Flex
        position="absolute"
        top="50%"
        transform="translateY(-50%)"
        right="0.5rem"
        zIndex="1"
        alignItems="center"
        justifyContent="center"
        bg="white"
        color="primary.500"
        borderRadius="50%"
        fontSize="1.8rem"
        p="0.8rem"
        cursor="pointer"
        onClick={() => sliderRef.current.slickNext()}
      >
        <BiRightArrow
          style={{ transform: "translateX(0.1rem)" }}
        />
      </Flex>
    </Box>
  )
}

const SliderCard = ({
  image,
  title,
  description,
  buttons
}) => (
  <>
    <Box
      borderRadius="1rem"
      height="100%"          
      // background="rgb(17,29,94)"                
      // backgroundImage={`linear-gradient(90deg, rgba(17,29,94,0.8660714285714286) 59%, rgba(227,40,69,0.4431022408963585) 100%), url(${image})`}            
      background="rgb(47,62,151)"
      backgroundImage={`linear-gradient(90deg, rgba(47,62,151,0.5943627450980392) 59%, rgba(176,20,44,0.3618697478991597) 100%), url(${image})`}                  
      backgroundPosition="center"
      backgroundRepeat="no-repeat"
      backgroundSize="cover"
    >       
      <Box
        position="absolute"
        maxWidth={{xs: "85vw", md: "80vw", lg: "56rem"}}  
        zIndex="1"      
        left="4rem"              
        top="50%"
        transform="translateY(-50%)"
      >
        {title && <Text
          as="h1"
          color="white"
          textShadow="0 0 0.5rem rgba(0,0,0,0.5)"          
          fontSize={{xs: "3.6rem", md: "4rem", lg: "4.2rem"}}
          fontWeight="800"                
          lineHeight={{xs: "4.6rem", md: "5rem", lg: "5.6rem"}}
          textAlign={{xs: "center", md: "left"}}
          mb="3rem"
        >
          {title}
        </Text>}
        {description && <Text
          as="p"
          color="accent.100"
          textShadow="0 0 0.5rem rgba(0,0,0,0.5)"
          fontSize={{xs: "1.6rem", md: "1.8rem"}}
          fontWeight="600"
          lineHeight="2.8rem"
          mb="5rem"
          textAlign={{xs: "center", md: "left"}}
        >
          {description}
        </Text>}
        {buttons?.length > 0 && <Flex
          style={{ gap: "2rem" }}
          alignItems="center" 
          flexWrap="wrap"  
          justifyContent={{xs: "center", md: "flex-start"}}       
        >
          {buttons.map((button, index) => (
            <Box
              key={index}
              width="fit-content"
            >
              <GetLink
                url={button.url}
              >
                <Button
                  minWidth="12rem"
                  p="1.5rem 3rem"
                  borderRadius="5rem"
                  width="fit-content"
                  variant={button.variant}
                >
                  {button.label}
                </Button>
              </GetLink>
            </Box>
          ))}                  
        </Flex>}
      </Box>   
    </Box>
  </>
)