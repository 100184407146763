import React from "react";

import { Flex } from "atoms";
import MemoLogo from "assets/icons/Logo";

export const InitialLoader = () => {
  return (
    <Flex
      width="100vw"
      height="100vh"
      alignItems="center"
      justifyContent="center"
      bg="rgba(255, 255, 255, 0.6)"
    >
      <MemoLogo
        width="28rem"
        height="28rem"        
      />
    </Flex>
  )
}