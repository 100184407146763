import { useEffect } from "react";
import { useParams } from "react-router-dom";

import SEO from "seo/SEO";
import { FacultyStaffPage } from "templates";
import { scrollToTop } from "utils/utilities";

const FacultyStaff = () => {

  const { type } = useParams();

  let title = ""
  if(type === "patron") title = "Our Patron's" 
  if(type === "faculty") title = "Faculty Staff"
  if(type === "management-staff") title = "Management Staff"
  if(type === "office-staff") title = "Office Staff"

  useEffect(() => {
    scrollToTop(window)
  }, [])

  return (
    <>
      <SEO
        title={`${title} | Vasudev College of Pharmacy Haldwani`}
        description={`${title} | Vasudev College of Pharmacy Haldwani`}
        keywords={`${title} | Vasudev College of Pharmacy Haldwani`}
      />   
      <FacultyStaffPage
        type={type}
        title={title}
      />   
    </>
  )
}

export default FacultyStaff;