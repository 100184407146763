const BtnColors = ["danger", "accent", "warning", "info"]

const LinkPrefix = ["http", "www", "mailto", "tel", "sms", "mailTo"]

const designations = [
  {label: "Principal", value: "principal", index: 1},
  {label: "Professor", value: "professor", index: 2},
  {label: "Associate Professor", value: "associate-professor", index: 3},
  {label: "Assistant Professor", value: "assistant-professor", index: 4},
  {label: "Guest Faculty", value: "guest-faculty", index: 5},
  {label: "Visiting Faculty", value: "visiting-faculty", index: 6},
  {label: "Administrative Staff", value: "administrative-staff", index: 7},  
  {label: "Librarian", value: "librarian", index: 8},
  {label: "Patron", value: "patron", index: 9},
  {label: "Management Staff", value: "management-staff", index: 10},
  {label: "Office Staff", value: "office-staff", index: 11},
]


export { BtnColors, LinkPrefix, designations }