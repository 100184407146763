import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";

import { getAlbumDetails } from "redux/actions";
import SEO from "seo/SEO";
import { GalleryDetailsPage } from "templates";
import { deSlugAndCapitalize, scrollToTop } from "utils/utilities";

const GalleryDetails = () => {

  const dispatch = useDispatch();
  const { album } = useSelector(state => state.data);
  const { slug } = useParams();

  useEffect(() => {
    dispatch(getAlbumDetails(slug));
    scrollToTop(window); 
  }, [dispatch, slug])

  return (
    <>
      <SEO
        title={`${album?.attributes?.slug === slug ?  album?.attributes?.title : deSlugAndCapitalize(slug)} | Vasudev College of Pharmacy Haldwani`}
        description={"Vasudev College of Pharmacy is one of the best pharmacy college in Haldwani, Nainital. We offer B.Pharma and D.Pharma courses in Haldwani, Nainital."}
        currentURL={`${process.env.REACT_APP_PUBLIC_URL}/album/${slug}`}
      />
      <GalleryDetailsPage/>
    </>
  )
}

export default GalleryDetails;