import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";

import { getEventDetails } from "redux/actions";
import SEO from "seo/SEO";
import { EventDetailsPage } from "templates";
import { deSlugAndCapitalize, scrollToTop } from "utils/utilities";

const EventDetails = () => {
    
  const dispatch = useDispatch();
  const { event } = useSelector(state => state.data);
  const { slug } = useParams();

  useEffect(() => {
    dispatch(getEventDetails(slug));
    scrollToTop(window); 
  }, [dispatch, slug])

  return (
    <>
      <SEO
        title={`${event?.attributes?.slug === slug ?  event?.attributes?.title : deSlugAndCapitalize(slug)} | Vasudev College of Pharmacy Haldwani`}
        description={"Vasudev College of Pharmacy is one of the best pharmacy college in Haldwani, Nainital. We offer B.Pharma and D.Pharma courses in Haldwani, Nainital."}
        currentURL={`${process.env.REACT_APP_PUBLIC_URL}/event/${slug}`}
      />
      <EventDetailsPage/>
    </>
  )
}

export default EventDetails;