export const appConstants = { 
  GET_INITIAL_DATA_REQUEST: "GET_INITIAL_DATA_REQUEST",
  GET_INITIAL_DATA_SUCCESS: "GET_INITIAL_DATA_SUCCESS",
  GET_INITIAL_DATA_FAILURE: "GET_INITIAL_DATA_FAILURE",
  SEND_CONTACT_US_REQUEST: "SEND_CONTACT_US_REQUEST",
  SEND_CONTACT_US_SUCCESS: "SEND_CONTACT_US_SUCCESS",
  SEND_CONTACT_US_FAILURE: "SEND_CONTACT_US_FAILURE",
  CLOSE_POP_UP: "CLOSE_POP_UP",
  RESET_APP: "RESET_APP",
  RESET: "RESET",
}

export const dataConstants = {
  GET_ABOUT_PAGE_DATA_REQUEST: "GET_ABOUT_PAGE_DATA_REQUEST",
  GET_ABOUT_PAGE_DATA_SUCCESS: "GET_ABOUT_PAGE_DATA_SUCCESS",
  GET_ABOUT_PAGE_DATA_FAILURE: "GET_ABOUT_PAGE_DATA_FAILURE",
  GET_EVENTS_REQUEST: "GET_EVENTS_REQUEST",
  GET_EVENTS_SUCCESS: "GET_EVENTS_SUCCESS",
  GET_EVENTS_FAILURE: "GET_EVENTS_FAILURE",
  GET_EVENT_DETAILS_REQUEST: "GET_EVENT_DETAILS_REQUEST",
  GET_EVENT_DETAILS_SUCCESS: "GET_EVENT_DETAILS_SUCCESS",
  GET_EVENT_DETAILS_FAILURE: "GET_EVENT_DETAILS_FAILURE",
  GET_COURSES_REQUEST: "GET_COURSES_REQUEST",
  GET_COURSES_SUCCESS: "GET_COURSES_SUCCESS",
  GET_COURSES_FAILURE: "GET_COURSES_FAILURE",
  GET_COURSE_DETAILS_REQUEST: "GET_COURSE_DETAILS_REQUEST",
  GET_COURSE_DETAILS_SUCCESS: "GET_COURSE_DETAILS_SUCCESS",
  GET_COURSE_DETAILS_FAILURE: "GET_COURSE_DETAILS_FAILURE",
  GET_ALBUMS_REQUEST: "GET_ALBUMS_REQUEST",
  GET_ALBUMS_SUCCESS: "GET_ALBUMS_SUCCESS",
  GET_ALBUMS_FAILURE: "GET_ALBUMS_FAILURE",
  GET_ALBUM_DETAILS_REQUEST: "GET_ALBUM_DETAILS_REQUEST",
  GET_ALBUM_DETAILS_SUCCESS: "GET_ALBUM_DETAILS_SUCCESS",
  GET_ALBUM_DETAILS_FAILURE: "GET_ALBUM_DETAILS_FAILURE",  
  GET_SYLLABUS_REQUEST: "GET_SYLLABUS_REQUEST",
  GET_SYLLABUS_SUCCESS: "GET_SYLLABUS_SUCCESS",
  GET_SYLLABUS_FAILURE: "GET_SYLLABUS_FAILURE",
  GET_RESULTS_REQUEST: "GET_RESULTS_REQUEST",
  GET_RESULTS_SUCCESS: "GET_RESULTS_SUCCESS",
  GET_RESULTS_FAILURE: "GET_RESULTS_FAILURE",
  GET_EXAM_SCHEDULE_REQUEST: "GET_EXAM_SCHEDULE_REQUEST",
  GET_EXAM_SCHEDULE_SUCCESS: "GET_EXAM_SCHEDULE_SUCCESS",
  GET_EXAM_SCHEDULE_FAILURE: "GET_EXAM_SCHEDULE_FAILURE",
  GET_FACULTY_REQUEST: "GET_FACULTY_REQUEST",
  GET_FACULTY_SUCCESS: "GET_FACULTY_SUCCESS",
  GET_FACULTY_FAILURE: "GET_FACULTY_FAILURE",
  RESET_DATA: "RESET_DATA",
  RESET: "RESET",
}