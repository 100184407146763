import React from "react";

import { Box, Grid, Text } from "atoms";
import { EventCard, GetLink, SectionHeading } from "molecules";
import { extractDate, getStrapiMediaUrl } from "utils/utilities";

export const HomeEvents = ({
  events
}) => {  
  return (
    <Box
      py={{xs: "8rem", lg: "10rem"}}          
      px={{xs: "3rem", md: "6rem"}}
      bg="white"
      as="section"
    >
      <SectionHeading        
        title="Events"
        description='Welcome to our Events section, your gateway to a dynamic array of enriching experiences and engaging gatherings.'
      />

      <Grid
        gridTemplateColumns={{
          xs: "1fr", 
          md: `${events?.length > 1 ? "repeat(2, 1fr)" : `repeat(${events?.length}, minmax(0, 48rem))`}}`,
          lg: `${events?.length > 2 ? "repeat(3, 1fr)" : `repeat(${events?.length}, minmax(0, 40rem))`}`
        }}
        justifyContent="center"
        gridGap="2.4rem"
        mt={{xs: "4rem", md: "6rem"}}        
      >
        {events?.map((item) => (
          <EventCard
            key={item?.id}
            id={item?.id}
            image={getStrapiMediaUrl(item?.image?.url)}
            title={item?.title}
            date={extractDate(item?.createdAt)}
            slug={item?.slug}
          />
        ))}
      </Grid>
      <Box
        width="fit-content"
        mx="auto"
        mt="1rem"
        _hover={{
          fontWeight: "700"
        }}
      >
        <GetLink
          url="/events"
        >
          <Text
            as="p"
            fontSize="1.6rem"
            color="#707070"            
          >
            View all 
            <Text
              as="span"
              fontSize="1.6rem"
              color="secondary.500"            
            >
              {" "}Events &#8594;
            </Text>
          </Text> 
        </GetLink>
      </Box>
    </Box>
  )
}