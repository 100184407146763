import React from "react";
import { AiOutlineMail, AiOutlinePhone } from "react-icons/ai";
import { FaLocationArrow } from "react-icons/fa";
import { useSelector } from "react-redux";
import { SocialIcon } from 'react-social-icons';

import { Box, Flex, Grid, Image, Text } from "atoms";
import { GetLink } from "molecules";
import { getStrapiMediaUrl } from "utils/utilities";

export const Footer = () => {

  const { footer } = useSelector(state => state.app);

  const onContactClick = (url) => {
    if(url === "#") return;
    window.open(url, "_blank");
  }

  const splitText = (text, url) => {
    const split = text?.split("<br/>");
    return split?.map((item, index) => ({      
      value: item,
      url: url + item,
    }))    
  }

  return (
    <>
      <Box
        width="100%"
        bg="primary.500"
        p={{xs: "3rem", lg: "6rem"}}
        backgroundImage={{md: "url('/assets/footer-bg.png')"}}
        backgroundPosition="center"
        backgroundRepeat="no-repeat"        
        backgroundSize="cover"
        color="white"
      >
        <Grid
          gridTemplateColumns={{xs: "1fr", xm: "0.8fr 1fr"}}
          gridGap="2.4rem"                    
        >
          <Box
            maxWidth="50rem"
          >
            {footer?.logo && <Box
              width="14rem"
              mb="1rem"
            >
              <Image
                src={getStrapiMediaUrl(footer?.logo?.url)}
                alt="Logo"
                width="100%"
                height="100%"
              />
            </Box>}            
            {footer?.text?.split("<br/>")?.map((item, index) => <Text              
              key={index}
              fontSize="1.4rem"
              lineHeight="2.2rem"
              fontWeight="600"
            >
              {item}
            </Text>)}

            {footer?.phone && <FooterContact
              Icon={<AiOutlinePhone/>}
              values={splitText(footer?.phone, "tel:")}              
              onContactClick={onContactClick}
            />}

            {footer?.email && <FooterContact
              Icon={<AiOutlineMail/>}
              values={splitText(footer?.email, "mailto:")}              
              onContactClick={onContactClick}
            />}
            {footer?.address && <FooterContact
              Icon={<FaLocationArrow/>}
              values={[{
                value: footer?.address,
                url: "#"
              }]}              
              onContactClick={onContactClick}
            />}
          </Box>

          <Grid
            gridTemplateColumns={{xs: "1fr 1fr", md: "1fr 1fr 1fr"}}
            gridGap="2.4rem"            
          >
            {footer?.nav?.map((item, index) => (
              <Box
                key={item?.id}              
              >
                <Text
                  fontSize="1.8rem"
                  fontWeight="800"
                  mb="3rem"
                >
                  {item?.title}
                </Text> 
                {item?.children?.map((child, index) => (
                  <FooterOption
                    key={child?.id}
                    title={child?.title}
                    url={child?.url || ""}
                  />
                ))}                     
              </Box>
            ))}   
          </Grid>       
        </Grid>
      </Box>

      {/* SOCIALS AND COPYRIGHT */}
      <Box
        width="100%"
        bg="accent.500"        
        p={{xs: "2rem 3rem", lg: "2.4rem 6rem"}}
      >
        <Flex
          color="white"
          alignItems="center"
          justifyContent="space-between"
          flexDirection={{xs: "column", xm: "row"}}
        >
          <Flex
            alignItems="center"
          >
            <Text>
              Follow us on
            </Text> 
            <Flex
              alignItems="center"
              ml="0.6rem"
            >
              {footer?.socials?.map((social) => (
                <SocialIcon 
                  key={social?.id}
                  url={social?.url} 
                  fgColor="white"
                  bgColor="transparent"
                  style={{
                    height: 28,
                    width: 28,
                    opacity: 0.8,
                    marginLeft: "0.4rem",                    
                  }}
                  className="social-icon"
                />              
              ))}
            </Flex>           
          </Flex>

          <Flex
            mt={{xs: "1rem", xm: "0"}}
          >
            <Text
              textAlign={{xs: "center", xm: "left"}}
            >
              <Text
                as="span"
              >
                © 2023 Vasudev College of Pharmacy |
              </Text>              
              <Text
                as="span"
                ml="0.6rem"
              >
                Developed by&nbsp;
                <Text
                  as="a"
                  href="https://pgkonnect.com"
                  target="_blank"
                  rel="noopener noreferrer"
                  color="accent.100"
                  fontWeight="bold"
                >                    
                  PGKPL                   
                </Text>
              </Text>
            </Text>            
          </Flex>
        </Flex>
      </Box>
    </>
  )
}

const FooterContact = ({
  Icon,
  values,
  onContactClick  
}) => (
  <Box
    width="fit-content"
    mt="1.6rem"
    opacity="0.8"
    _hover={{
      opacity: 1
    }}
  >    
    <Flex
      alignItems="center"
      style={{
        gap: "0.8rem"
      }}
    >
      <Flex
        fontSize="1.8rem"
        alignItems="center"
        justifyContent="center"
      >
        {Icon}
      </Flex>        
      <Text
        className="break-word"
      >
        {values?.map((value, index) => (
          <Text
            as="span"
            key={index}
          >
            <Text
              as="span"
              onClick={() => onContactClick(value?.url)}            
              cursor={value?.url === "#" ? "default" : "pointer"}
              _hover={{
                textDecoration: value?.url !== "#" ? "underline" : "none",
                color: value?.url !== "#" ? "secondary.500" : "inherit"
              }}
            >              
              {value?.value}                             
            </Text>
            {index !== values?.length - 1 && " | "}
          </Text>
        ))}
      </Text>
    </Flex>    
  </Box>
)

const FooterOption = ({
  title,
  url,
}) => (
  <Box
    width="fit-content"
    mb="1.2rem"
  >
    <GetLink
      url={url}
    >
      <Text
        fontSize="1.6rem"
        fontWeight="500"
        color="white"
        opacity="0.8"      
        _hover={{
          opacity: 1,
          fontWeight: "600"
        }}
      >
        {title}
      </Text>
    </GetLink>
  </Box>
)