import React from "react";
import { useSelector } from "react-redux";

import { Box, Flex, Text } from "atoms";
import { PageHero } from "molecules";

const data = [
  {
    title: "Terms & Conditions",
    values: [
      "Terms & Conditions Consent",    
    ],
  },
  {
    title: "1. Intellectual Property",
    values: [
      "All content on our website, including text, images, graphics, logos, and other materials, is protected by applicable copyright and trademark laws. You may not use, reproduce, distribute, or create derivative works based on our content without our explicit written permission.",    
      "By accessing or using our Services, you agree to comply with these Terms and Conditions. If you disagree with any part of these terms, you may not access the Services."
    ],
  },
  {
    title: "2. User Content",
    values: [
      'You may submit content, comments, or other materials ("User Content") to our website. By submitting User Content, you grant us a non-exclusive, worldwide, royalty-free, perpetual, irrevocable, and fully sublicensable right to use, reproduce, modify, adapt, publish, translate, create derivative works from, distribute, and display such User Content.'
    ],
  },
  {
    title: "3. Privacy",
    values: [
      'Your use of our Services is also governed by our Privacy Policy. By using our Services, you consent to the practices described in our Privacy Policy.'
    ],
  },
  {
    title: "4. Limitation of Liability",
    values: [
      'We shall not be liable for any indirect, incidental, special, consequential, or punitive damages, including without limitation, loss of profits, data, use, goodwill, or other intangible losses, resulting from your access to or use of our Services.'
    ],
  },
  {
    title: "5. Links to Third-Party Websites",
    values: [
      'Our Services may contain links to third-party websites or services that are not owned or controlled by us. We have no control over and assume no responsibility for the content, privacy policies, or practices of any third-party websites or services.'
    ],
  },
  {
    title: "6. Changes to Terms and Conditions",
    values: [
      'We may update our Terms and Conditions from time to time. The updated version will be indicated by an updated "Last Updated" date. By continuing to access or use our Services after those changes become effective, you agree to the revised Terms and Conditions.'
    ],
  }
]

export const TermsAndConditionsPage = () => {

  const { footer } = useSelector(state => state.app);

  return (
    <>
      <PageHero
        image="/assets/page-hero.jpg"
        title="Terms and Conditions"
        breadcrumbs={[
          {
            title: "Home",
            url: "/"
          },
          {
            title: "Terms and Conditions",
            url: "/terms-and-conditions"
          }
        ]}
      />
      <Box
        bg="white"                
        p={{xs: "8rem 3rem", lg: "10rem 3rem"}}
        as="section"
        maxWidth={{xs: "100%", lg: "120rem"}}
        mx="auto"
      >
        {data?.map((item, index) => (
          <PopulateTitleValue
            key={index}
            title={item.title}
            values={item.values}
          />
        ))}
        <PopulateTitleValue
          title="7. Contact Us"
          values={[
            `If you have any questions or concerns about these Terms and Conditions, please contact us at ${footer?.email?.split("<br/>")?.map((item) => <>{item}{" "}</>)}.`,
            "By using our website, you agree to the terms outlined in these Terms and Conditions."
          ]}
        />
      </Box>
    </>
  )
}

const PopulateTitleValue = ({ title, values, type="paragraph" }) => (
  <Box
    mb="3rem"
  >
    {title && <Text
      as="h3"
      fontSize={{xs: "2.2rem", md: "2.4rem"}}
      fontWeight="800"
      color="primary.500"
      mb="1rem"
    >
      {title}
    </Text>}    
    {values?.map((value, index) => (
      <>
        {type === "list" && <Flex
          alignItems="center" 
          mb="1.5rem"         
        >
          <Box
            width="1rem"
            height="1rem"
            bg="secondary.500"
            borderRadius="50%"
            mr="1rem"            
          />
          <Text      
            key={index}
            as="p"
            fontSize="1.6rem"
            fontWeight="600"
            color="#707070"
          >
            {value}
          </Text>
        </Flex>}
        {type === "paragraph" && <Text      
          key={index}
          as="p"
          fontSize="1.6rem"
          fontWeight="600"
          mb="1.5rem"
          color="#707070"
          className="text-justify"           
        >
          {value}
        </Text>}
      </>     
    ))}
  </Box>
)