import React, { useEffect } from "react";
import { DebounceInput } from "react-debounce-input";
import Pagination from "react-js-pagination";

import { Box, Flex, Text } from "atoms";
import { scrollToTop } from "utils/utilities";

export const TitleSearchLayout = ({
  title,
  search,
  setSearch,
  totalCount,
  resultPerPage,
  page,
  setPage,
  children,
  notFound
}) => {

  useEffect(() => {
    setPage(1);
  }, [search, setPage]);

  useEffect(() => {
    scrollToTop(window);
  }, [page]);


  return (
    <Box
      bg="white"                
      p={{xs: "8rem 3rem", md: "10rem 3rem"}}
      as="section"
      maxWidth={{xs: "100%", md: "120rem"}}
      mx="auto"
    >
      <Flex
        alignItems={{md: "center"}}
        justifyContent="space-between"
        flexDirection={{xs: "column", md: "row"}}
      >
        <Text
          as="h2"
          fontSize={{xs: "3rem", md: "3.6rem"}}
          fontWeight="800"
          mb="2rem"
          color="primary.500"
        >
          {title}
        </Text>

        <Box
          maxWidth={{xs: "100%", md: "40rem"}}
        >
          <DebounceInput
            minLength={3}
            debounceTimeout={400}
            placeholder="Search"  
            type="search" 
            value={search}
            onChange={(e) => setSearch(e.target.value)}          
          />
        </Box>
      </Flex> 
      {children}     
      {resultPerPage < totalCount &&  <Flex
        justifyContent="center"
        my="4rem"
      >
        <Pagination
          activePage={page}
          itemsCountPerPage={resultPerPage}
          totalItemsCount={totalCount}
          onChange={(e) => setPage(e)}
          nextPageText=">"
          prevPageText="<"
          firstPageText="<<"
          lastPageText=">>"
          itemClass="pagination-item"
          linkClass="page-link"
          activeClass="pagination-item-active"
          activeLinkClass="pagination-link-active"
        />
      </Flex>}       
      {totalCount > 0 &&  <Text
        textAlign="center"
        fontSize="1.4rem"
        fontWeight="600"
        color="accent.500"
        my="2rem"
      >
        Total Entries Found : {totalCount}
      </Text>}   
      {totalCount === 0 && <Box
        p="4rem"
      >
        <Text
          as="h4"
          fontSize="2.4rem"
          fontWeight="600"
          color="secondary.500"
          textAlign="center"
          textTransform="capitalize"
        >
          {notFound}
        </Text>
      </Box>}
    </Box>
  )
}