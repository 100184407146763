import React from "react";
import { useSelector } from "react-redux";

import { Box, Flex, Grid, Text } from "atoms";
import { Contact, ContactForm, PageHero } from "molecules";
import { SocialIcon } from "react-social-icons";

export const ContactUsPage = () => {

  const { footer } = useSelector(state => state?.app)

  return (
    <>
      <PageHero
        image="/assets/page-hero.jpg"
        title="Contact Us"
        breadcrumbs={[
          {
            title: "Home",
            url: "/"
          },
          {
            title: "Contact Us",
            url: "/contact-us"
          }
        ]}
      />
      <Box
        bg="white"
        p={{xs: "8rem 3rem", md: "10rem 6rem"}}
        as="section"
      >
        <Grid
          gridTemplateColumns={{xs: "1fr", lg: "1fr 1fr"}}
          gridGap="2.4rem"
        >
          <Box>
            <ContactForm/>
          </Box>
          <Box>
            <Contact
              title="Let's Contact Us"
              description="We are always ready to help you. There are many ways to contact us. You may drop us a line, give us a call or send an email, choose what suits you the most."
              mobile={footer?.phone?.split("<br/>")}
              email={footer?.email?.split("<br/>")}
              address={footer?.address}
            />
            <Box
              mt="3rem"
              borderTop="1px solid"
              borderColor="#f3f3f3"
            >
              <Text
                as="h3"
                fontSize="2.4rem"
                fontWeight="800"
                mb="1.5rem"
                color="primary.500"
              >
                Social Media
              </Text>
              <Text
                as="p"
                mb="2.5rem"
                color="#707070"
                fontSize="1.6rem"                
              >
                Connect with us on social media to get the latest updates. We post about our events, college news, and more.
              </Text>
              <Flex
                alignItems="center"
              >
                {footer?.socials?.map((social, index) => (
                  <SocialIcon 
                    key={social?.id}
                    url={social?.url} 
                    fgColor="#E32845"
                    bgColor="#e3e3e3"
                    style={{
                      height: 35,
                      width: 35,                      
                      marginRight: "1rem",                    
                    }}                    
                  />              
                ))}
              </Flex>
            </Box>
          </Box>
        </Grid>
      </Box>
    </>
  )
}