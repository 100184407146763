import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { PopUpModal } from "molecules";
import { appConstants } from "redux/constants";
import { getStrapiMediaUrl } from "utils/utilities";
import { HomeAbout, HomeContact, HomeCourses, HomeEvents, HomeKnowlegdeResources, HomeSlider, HomeTestimonials } from "./molecules";

export const HomePage = () => { 

  const dispatch = useDispatch();
  const { home, popUp } = useSelector(state => state.app);
  const [ delayPopUp, setDelayPopUp ] = useState(false);

  useEffect(() => {
    setTimeout(() => {
      setDelayPopUp(true);
    }, 5000);
  }, []);

  return (
    <>
      {(popUp && home?.page?.popUp?.show && delayPopUp) && <PopUpModal
        isOpen={(popUp && home?.page?.popUp?.show && delayPopUp)}
        onRequestClose={() => dispatch({type: appConstants.CLOSE_POP_UP})}
        ariaHideApp={false}
        title={home?.page?.popUp?.title}
        image={getStrapiMediaUrl(home?.page?.popUp?.image?.url)}
        links={home?.page?.popUp?.links}
      />}
      {home?.page?.slider && <HomeSlider
        slides={home?.page?.slider}
      />}         
      {(home?.page?.about || home?.page?.featured) && <HomeAbout
        about={home?.page?.about}
        featured={home?.page?.featured}
      />
      }         
      {home?.courses && <HomeCourses
      courses={home?.courses}
      />}
      {home?.events && <HomeEvents
        events={home?.events}
      />}
      {home?.testimonials && <HomeTestimonials
        testimonials={home?.testimonials}
      />}
      {home?.knowledgeResources && <HomeKnowlegdeResources
        data={home?.knowledgeResources}
      />}
      <HomeContact/>
    </>
  );
};
