import { Box, Text } from "atoms";

export const SectionHeading = ({
  heading,
  title,
  description,
}) => {
  return (
    <Box
      maxWidth={{xs: "100%", md: "48rem"}}
      mx="auto"
    >
      {heading && <Text
        as="span"
        fontSize={{xs: "1.5rem", md: "1.8rem"}}
        fontWeight="600"
        color="secondary.500"
        textAlign="center"
        mb="0.5rem"
        display="block"
      >
        {heading}
      </Text>}
      {title && <Text
        as="h2"
        fontSize={{xs: "3rem", md: "3.5rem", lg: "4rem"}}
        fontWeight="800"
        color="primary.500"
        textAlign="center"
        mb="1.5rem"
        textTransform="capitalize"
      >
        {title}
      </Text>}
      {description && <Text
        as="p"
        fontSize="1.6rem"
        fontWeight="400"
        color="#707070"
        textAlign="center"
        mb="0.5rem"
      >
        {description}
      </Text>}
    </Box>
  )
}