import React from "react";

import { Box, Button, Flex, Image, Text } from "atoms";
import { GetLink, PageHero } from "molecules";

export const ErrorPage = () => {
  return (
    <>
      <PageHero
        image="/assets/page-hero.jpg"
        title="404 Error"
        breadcrumbs={[
          {
            title: "Home",
            url: "/"
          },
          {
            title: "Not Found",
            url: "#"
          }
        ]}
      />
      <Flex
        bg="white"                
        p={{xs: "8rem 3rem", md: "10rem 3rem"}}
        as="section"
        maxWidth={{xs: "100%", md: "120rem"}}
        mx="auto"
        alignItems="center"
        justifyContent="center"
        flexDirection="column"
      >
        <Box
          width={{xs: "100%", md: "64rem"}} 
          mb="4rem"
        >
          <Image
            src="/assets/404.png"
            alt="404 Error"
            width="100%"
            height="100%"            
          />
        </Box>
        <Text
          as="h2"
          fontSize="3rem"
          fontWeight="800"
          mb="2rem"
          color="primary.500"
        >
          Page Not Found
        </Text>
        <Text
          as="p"
          fontSize="1.6rem"
          fontWeight="600"
          color="#707070"
          textAlign="center"
          maxWidth="64rem"          
        >
          The page you are looking for might have been removed had its name changed or The page you are looking for might have been  had its name changed or is temporarily unavailable.
        </Text>
        <Box
          width="fit-content"
          mt="4rem"
        >
          <GetLink
            url="/"
          >
            <Button
              variant="primary"
              p="1.5rem 3rem"              
            >
              Go to Home
            </Button>
          </GetLink>
        </Box>
      </Flex>
    </>
  )
}
