import React from "react";
import { FaCheck } from "react-icons/fa";
import { TbNurse } from "react-icons/tb";

import { Box, Flex, Grid, Image, Text } from "atoms";
import { GetLink, SectionHeading } from "molecules";
import { getStrapiMediaUrl } from "utils/utilities";

export const HomeAbout = ({
  about,
  featured
}) => {
  return (
    <Box
      py={{xs: "8rem", lg: "10rem"}}          
      px={{xs: "3rem", md: "6rem"}}
      bg="white"      
    >
      {about?.head && <SectionHeading
        heading={about?.head?.heading}
        title={about?.head?.title}
        description={about?.head?.description}
      />}

      {about?.cards && <Grid        
        gridTemplateColumns={{
          xs: "1fr", 
          xm: "1fr 1fr", 
          lg: `${about?.cards?.length > 2 ? "repeat(3, 1fr)" : `repeat(${about?.cards?.length}, minmax(0, 40rem))`}`          
        }}
        justifyContent="center"
        gridGap="2.4rem"
        mt={{xs: "3rem", md: "6rem" }}
        as="section"
      >        
        {about?.cards?.map((item) => (
          <AboutCard
            key={item?.id}
            Icon={<TbNurse/>}
            title={item?.title}
            description={item?.description}
            url="#"
          />
        ))}               
      </Grid>}   

      {featured && <Box
        mt={{xs: "5rem", md: "6rem" }}
        as="section"       
      >
        <Grid
          gridTemplateColumns={{xs: "1fr", lg: "1.5fr 1fr"}}
          gridGap="2.4rem"          
        >
          <Box
            width="100%"            
            p={{md: "1.2rem"}}
          >
            {featured?.title && <Text
              as="h2"
              mb="2rem"  
              textTransform="capitalize"
              color="primary.500"
              fontSize="2rem"
              fontWeight="800"
            >
              {featured?.title}
            </Text>}
            {featured?.description && <Text
              as="p"
              fontSize="1.6rem"
              fontWeight="500"
              color="#707070"
            >
              {featured?.description}
            </Text>}
            {featured?.featured && <Grid
              gridTemplateColumns={{xs: "1fr", md: "repeat(3, 1fr)", lg: "1fr 1fr"}}
              gridGap="2.4rem"
              my={{xs: "2rem", md: "3rem"}}
            >
              {featured?.featured?.map((item) => (
                <Flex
                  key={item?.id}
                  alignItems="center"               
                >
                  <Flex
                    alignItems="center"
                    justifyContent="center"
                    fontSize="1.6rem"
                    lineHeight="1.6rem"
                    color="accent.100"
                  >
                    <FaCheck/>
                  </Flex>
                  <Text
                    ml="1rem"
                    as="p"
                    fontSize="1.6rem"
                    fontWeight="500"
                    color="#707070"                  
                    lineHeight="1.6rem"
                  >
                    {item?.value}
                  </Text>
                </Flex>
              ))}              
            </Grid>}

            {featured?.highlights && <Flex
              justifyContent="space-between"
              style={{
                gap: "1rem"
              }}
              maxWidth={{xs: "100%", xm: "90%"}}
              flexWrap="wrap"              
            >
              {featured?.highlights?.map((item) => (
                <Box
                  key={item?.id}
                  p="1.2rem"
                  width={{xs: "fit-content"}}
                >
                  <Text
                    as="h2"
                    fontSize={{xs: "4rem", md: "6rem"}}
                    mb="0.5rem"
                    fontWeight="800"
                    color="primary.500"                  
                  >
                    {item?.title}
                    <Text
                      as="span"
                      fontSize={{xs: "4rem", md: "6rem"}}
                      fontWeight="800"
                      color="secondary.500"
                    >
                      +
                    </Text>
                  </Text>
                  <Text
                    as="p"
                    fontSize="1.6rem"
                    fontWeight="600"
                    color="#707070"                    
                  >
                    {item?.description}
                  </Text>
                </Box>
              ))}
            </Flex>}
          </Box>
          {featured?.image && <Box
            width="100%"            
            p="1.2rem"
          >           
            <Image
              src={getStrapiMediaUrl(featured?.image?.url)}
              alt="Featured Image"
              width="100%"
              height="100%"
              objectFit="contain"
            />            
          </Box>}
        </Grid>
      </Box>}   
    </Box>    
  )
}

const AboutCard = ({
  Icon,
  title,
  description,
  url
}) => (
  <Box
    p={{xs: "1.8rem 3rem", md: "3rem"}}
    bg="#F3F3F3"
    width="100%"
    borderRadius="1rem"
  >
    <Flex
      alignItems="center"            
    >
      {Icon && <Flex
        alignItems="center"
        justifyContent="center"
        fontSize={{xs: "2.4rem", md: "2.8rem"}}
        color="secondary.500"
        mr="1rem"
      >
        {Icon}
      </Flex>}
      {title && <Text
        as="h3"
        textTransform="capitalize"
        color="primary.500"
        fontSize={{xs: "2rem", md: "2.4rem"}}
        fontWeight="800"                 
      >
        {title}
      </Text>}
    </Flex>
    {description && <Text
      my={{xs: "1.5rem", md: "2rem"}}
      color="#707070"
      fontSize="1.6rem"
      fontWeight="500"
      className="text-justify"           
    >
      {description}
    </Text>}
    {url && <Box
      width="fit-content"
    >
      <GetLink
        url={url}
      >
        <Flex
          alignItems="center"
          color="primary.500"
          fontWeight="500"
          opacity="0.9"
          _hover={{
            opacity: "1"                
          }}
        >
          <Text
            fontSize="1.6rem"
            mr="0.6rem"
          >
            Read More &#8594;
          </Text>                
        </Flex>
      </GetLink>
    </Box>}
  </Box>
)