import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { Grid } from "atoms";
import { DocumentCard, PageHero, TitleSearchLayout } from "molecules";
import { getExamSchedules } from "redux/actions";
import { getStrapiMediaUrl } from "utils/utilities";

export const ExamSchedulePage = () => {

  const [ search, setSearch ] = useState("");
  const [ page, setPage ] = useState(1);

  const dispatch = useDispatch();
  const { examSchedule } = useSelector(state => state.data);

  useEffect(() => {
    dispatch(getExamSchedules(page, 12, search));
  }, [search, page, dispatch]);

  return (
    <>
      <PageHero
        image="/assets/page-hero.jpg"
        title="Exam Schedules"
        breadcrumbs={[
          {
            title: "Home",
            url: "/"
          },
          {
            title: "Exam Schedules",
            url: "/students/exam-schedule"
          }
        ]}
      />
      <TitleSearchLayout
        title="Exam Schedules"
        search={search}
        setSearch={setSearch}
        totalCount={examSchedule?.meta?.pagination?.total}
        resultPerPage={examSchedule?.meta?.pagination?.pageSize}
        page={page}
        setPage={setPage}
        notFound="No exam schedules found"
      >        
        <Grid
          gridTemplateColumns={{xs: "1fr", xm: "repeat(2, 1fr)"}}
          gridGap="1.5rem"          
          mt="4rem"        
        >
          {examSchedule?.data?.map((item) => (
            <DocumentCard
              key={item?.id}
              title={item?.attributes?.title}
              url={item?.attributes?.data?.type === "media" ? getStrapiMediaUrl(item?.attributes?.data?.file?.data?.attributes?.url) : item?.attributes?.data?.url}
              bg="#F3F3F3"
            />
          ))}
        </Grid>
      </TitleSearchLayout>       
    </>
  )
}