import { Box, Grid, Text } from "atoms";
import { CourseCard, GetLink, SectionHeading } from "molecules";
import { getStrapiMediaUrl } from "utils/utilities";

export const HomeCourses = ({
  courses
}) => {
  return (
    <Box
      py={{xs: "8rem", lg: "10rem"}}          
      px={{xs: "3rem", md: "6rem"}}
      bg="#f3f3f3"
      as="section"
    >
      <SectionHeading        
        title="Our Courses"
        description='Get started today and unlock a world of knowledge, all at your fingertips.'
      />

      <Grid
        gridTemplateColumns={{
          xs: "1fr", 
          md: `${courses?.length > 1 ? "repeat(2, 1fr)" : `repeat(${courses?.length}, minmax(0, 48rem))`}}`,
          lg: `${courses?.length > 2 ? "repeat(3, 1fr)" : `repeat(${courses?.length}, minmax(0, 40rem))`}`
        }}
        justifyContent="center"
        gridGap="2.4rem"
        mt={{xs: "4rem", md: "6rem"}}           
      >
        {courses?.map((item) => (
          <CourseCard
            key={item?.id}
            id={item?.id}
            slug={item?.slug}
            image={getStrapiMediaUrl(item?.image?.url)}
            title={item?.title}
            description={item?.description}
            time={item?.duration}
            seats={item?.seats}
          />
        ))}        
      </Grid>
      <Box
        width="fit-content"
        mx="auto"
        mt="1rem"
        _hover={{
          fontWeight: "700"
        }}
      >
        <GetLink
          url="/courses"
        >
          <Text
            as="p"
            fontSize="1.6rem"
            color="#707070"            
          >
            View more 
            <Text
              as="span"
              fontSize="1.6rem"
              color="secondary.500"            
            >
              {" "}Courses &#8594;
            </Text>
          </Text> 
        </GetLink>
      </Box>
    </Box>
  )
}