import React from "react";
import { BsFillCalendar2DateFill } from "react-icons/bs";
import { useSelector } from "react-redux";

import { Box, Flex, Grid, Image, Text } from "atoms";
import { DocumentCard, LinkCard, PageHero } from "molecules";
import { getStrapiMediaUrl } from "utils/utilities";

export const EventDetailsPage = () => {

  const { event, loading } = useSelector(state => state.data);

  const formatDate = (date) => {    
    const d = new Date(date);
    const day = d.getDate();
    const month = d.toLocaleString('default', { month: 'short' });
    const year = d.getFullYear();
    return `${day} ${month} ${year}`;    
  }

  return (
    <>
      <PageHero
        image={getStrapiMediaUrl(event?.attributes?.image?.data?.attributes?.url)}
        title="Event Details"
        breadcrumbs={[
          {
            title: "Home",
            url: "/"
          },
          {
            title: "Events",
            url: "/events"
          },
          {
            title: (event && !loading) ? event?.attributes?.title : "Loading...",
            url: "#"
          }
        ]}
      />

      {event?.attributes && <Box
        bg="white"                
        p={{xs: "8rem 3rem", lg: "10rem 3rem"}}
        as="section"
        maxWidth={{xs: "100%", lg: "120rem"}}
        mx="auto"
      >
        {event?.attributes?.title && <Text
          as="h2"
          fontSize={{xs: "3rem", md: "3.6rem"}}
          fontWeight="800"
          mb="2rem"
          color="primary.500"
        >
          {event?.attributes?.title}
        </Text>}
        <Grid
          gridTemplateColumns={{xs: "1fr", xm: "1.8fr 1fr", lg: "2fr 1fr"}}
          gridGap="2.4rem"          
        >
          <Box>            
          {event?.attributes?.image && <Box
              mb={{xs: "2rem", md: "3rem"}}
              maxHeight="48rem"
              overflow="hidden"              
            >
              <Image
                src={getStrapiMediaUrl(event?.attributes?.image?.data?.attributes?.url)}
                alt={event?.attributes?.title}
                width="100%"
                height="100%"
                objectFit="cover"
                preview={true}
              />
            </Box>}
            <Flex
              alignItems="center"
              mb="1.5rem"
            >
              <Flex
                alignItems="center"
                justifyContent="center"
                fontSize="2.4rem"
                color="secondary.500"
                mr="0.5rem"
              >
                <BsFillCalendar2DateFill/>
              </Flex>
              {event?.attributes?.createdAt && <Text
                as="p"
                fontSize="1.6rem"
                fontWeight="600"
                color="#707070"
              >
                {formatDate(event?.attributes?.createdAt)}
              </Text>}
            </Flex>
            {event?.attributes?.data?.map((data) => {
              switch(data.__component){
                case "common.text":
                  return <PopulateTitleValue
                    key={data?.id}
                    title={data?.title}
                    values={data?.description?.split("<br/>")}
                  />

                case "common.list":
                  return <PopulateTitleValue
                    key={data?.id}
                    title={data?.title}
                    values={data?.data?.map((item) => item.value)}
                    type="list"
                  />

                default:
                  return null;
              }
            })}            
          </Box>

          <Box
            display={{ md: "flex", xm: "block" }}
            alignItems="flex-start"
            style={{ gap: "2.4rem" }}
          > 
            {event?.attributes?.docs && <Box
              width="100%"
              bg="#f3f3f3"
              p="3rem"              
              mb={{xs: "3rem", md: "0", xm: "3rem"}}
            >              
              <Text
                as="h3"
                fontSize="2.2rem"
                fontWeight="800"
                mb="2rem"
                color="primary.500"
              >
                Related Documents
              </Text>

              <Grid
                gridTemplateColumns="1fr"
                gridGap="1rem"                  
              >
                {event?.attributes?.docs?.map((item) => (
                  <DocumentCard
                    key={item?.id}
                    title={item?.title}
                    url={getStrapiMediaUrl(item?.file?.data?.attributes?.url)}
                  />
                ))}
              </Grid>
            </Box>}

            {event?.attributes?.links &&<Box
              width="100%"
              bg="#f3f3f3"
              p="3rem"              
            >
              <Text
                as="h3"
                fontSize="2.2rem"
                fontWeight="800"
                mb="2rem"
                color="primary.500"
              >
                Related Links
              </Text>
              <Grid
                gridTemplateColumns="1fr"
                gridGap="1rem"
              >
                {event?.attributes?.links?.map((item) => (
                  <LinkCard
                    key={item?.id}
                    title={item?.title}
                    url={item?.url}
                  />
                ))}
              </Grid>            
            </Box>}
          </Box>
        </Grid>
      </Box>}
    </>
  )
}

const PopulateTitleValue = ({ title, values, type="paragraph" }) => (
  <Box
    mb={{xs: "2rem", md: "3rem"}}
  >
    {title && <Text
      as="h3"
      fontSize="2.4rem"
      fontWeight="800"
      color="primary.500"
      mb="1rem"
    >
      {title}
    </Text>}    
    {values?.map((value, index) => (
      <>
        {type === "list" && <Flex
          alignItems="center" 
          mb="1.5rem"         
        >
          <Box
            width="1rem"
            height="1rem"
            bg="secondary.500"
            borderRadius="50%"
            mr="1rem"            
          />
          <Text      
            key={index}
            as="p"
            fontSize="1.6rem"
            fontWeight="600"
            color="#707070"
          >
            {value}
          </Text>
        </Flex>}
        {type === "paragraph" && <Text      
          key={index}
          as="p"
          fontSize="1.6rem"
          fontWeight="600"
          mb="1.5rem"
          color="#707070"
          className="text-justify"           
        >
          {value}
        </Text>}
      </>     
    ))}
  </Box>
)