import React, { useState } from "react";
import { MdAdd } from "react-icons/md";

import { Box, Flex, Text } from "atoms";
import { GetLink } from "molecules";
import { isMobile, isTab } from "utils/utilities";

export const HeaderOption = ({
  title,
  url,
  children,
  setMobileMenu,
  type
}) => {

  const [isHovered, setIsHovered] = useState(false);
  
  const isSmall = isMobile() === true || isTab() === true;

  return (
    <Box
      position="relative"
      onMouseEnter={() => {if(!isSmall) children?.length && setIsHovered(true)}}
      onMouseLeave={() => {
        if(children?.length && !isSmall) setTimeout(() => {
          setIsHovered(false)
        }, 200)
      }}
      onClick={() => setIsHovered(!isHovered)}
    >
      {children?.length ? <Box>
        <Title
          title={title}
          length={children?.length}
        />
      </Box> : <GetLink
        url={url}
        header={true}
        onClick={() => setMobileMenu(false)}
        type={type}
      >
        <Title
          title={title}
          length={children?.length}
        />
      </GetLink>}
             
      <Box
        position={{lg: "absolute"}}        
        top="100%"
        left="50%"
        transform="translateX(-50%)"        
        display={isHovered ? "block" : "none"}
        pt={{lg: "3rem"}}        
      >        
        <Box 
          display={children?.length ? "block" : "none"}         
          minWidth={{xs: "100%", lg: "24rem"}}
          width="fit-content"
          bg="white"
          boxShadow="0 0 0.5rem rgba(0,0,0,0.2)"
          borderRadius="0.4rem"
          p="1rem 1.5rem"          
        >
          {children?.map((child, index) => (
            <GetLink
              key={child.id}
              url={child.url}
              onClick={() => setMobileMenu(false)}              
              header={true}
              type={child.type || ""}
            >
              <Text
                color="black"
                opacity="0.85"
                borderBottom={`1px solid}`}
                borderColor={index === children.length - 1 ? "transparent" : "grey.200"}
                fontWeight="500"
                py="1.2rem"
                _hover={{
                  color: "secondary.500",
                  fontWeight: "700",
                  opacity: "1"
                }}
                transition="0.2s all ease-in-out"                
              >
                {child.title}
              </Text> 
            </GetLink>
          ))}                   
        </Box>
      </Box>
    </Box>
  )
}

const Title = ({
  title,
  length  
}) => (
  <Flex
    transition="0.3s all ease-in-out"            
    color="white"
    alignItems="center"   
    cursor="pointer"         
    _hover={{
      color: "secondary.500",
      fontWeight: "600"
    }}
    style={{
      gap: "0.5rem"
    }}
    p={{xs: "1.2rem", lg: "0"}}
    justifyContent={{xs: "space-between", lg: "start"}}
  >        
    <Text              
      fontSize={{xs: "1.8rem", lg: "1.6rem"}}
      fontWeight="500"
    >
      {title}
    </Text>
    <Box
      alignItems="center"
      justifyContent="center"
      fontSize={{xs: "1.7rem", lg: "1.5rem"}}
      display={length > 0 ? "flex" : "none"}
    >
      <MdAdd/>
    </Box>
  </Flex>
)