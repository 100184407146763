import { dataConstants } from "redux/constants";
import Axios from "utils/axios";

export const getAboutPage = () => {
  return async dispatch => {
    try{
      dispatch({ type: dataConstants.GET_ABOUT_PAGE_DATA_REQUEST });      
      const { data } = await Axios.get(`/about-us-page?populate=deep`);                        
      dispatch({ 
        type: dataConstants.GET_ABOUT_PAGE_DATA_SUCCESS,
        payload: data?.data?.attributes?.data
      });            
    }catch(error){      
      dispatch({ 
        type: dataConstants.GET_ABOUT_PAGE_DATA_FAILURE, 
        payload: { error: error?.response?.data }
      });
    }
  }
}

export const getEvents = (page, limit, search) => {
  return async dispatch => {
    try{
      dispatch({ type: dataConstants.GET_EVENTS_REQUEST });  
      let URL = `/events?pagination[page]=${page}&pagination[pageSize]=${limit}&populate=deep&sort=createdAt:desc`    
      if(search) URL += `&filters[title][$contains]=${search}`;
      const { data } = await Axios.get(URL);      
      dispatch({ 
        type: dataConstants.GET_EVENTS_SUCCESS,
        payload: data
      });            
    }catch(error){      
      dispatch({ 
        type: dataConstants.GET_EVENTS_FAILURE, 
        payload: { error: error?.response?.data }
      });
    }
  }
}

export const getEventDetails = (slug) => {
  return async dispatch => {
    try{
      dispatch({ type: dataConstants.GET_EVENT_DETAILS_REQUEST });      
      const { data } = await Axios.get(`/events?filters[slug][$eq]=${slug}&populate=deep`);      
      dispatch({ 
        type: dataConstants.GET_EVENT_DETAILS_SUCCESS,
        payload: data?.data?.[0]
      });            
    }catch(error){      
      dispatch({ 
        type: dataConstants.GET_EVENT_DETAILS_FAILURE, 
        payload: { error: error?.response?.data }
      });
    }
  }
}

export const getCourses = (page, limit, search) => {
  return async dispatch => {
    try{
      dispatch({ type: dataConstants.GET_COURSES_REQUEST });  
      let URL = `/courses?pagination[page]=${page}&pagination[pageSize]=${limit}&populate=deep&sort=createdAt:desc`    
      if(search) URL += `&filters[title][$contains]=${search}`;
      const { data } = await Axios.get(URL);        
      dispatch({ 
        type: dataConstants.GET_COURSES_SUCCESS,
        payload: data
      });                  
    }catch(error){      
      dispatch({ 
        type: dataConstants.GET_COURSES_FAILURE, 
        payload: { error: error?.response?.data }
      });
    }
  }
}

export const getCourseDetails = (slug) => {
  return async dispatch => {
    try{
      dispatch({ type: dataConstants.GET_COURSE_DETAILS_REQUEST });      
      const { data } = await Axios.get(`/custom/course/${slug}`);
      dispatch({ 
        type: dataConstants.GET_COURSE_DETAILS_SUCCESS,
        payload: data
      });            
    }catch(error){      
      dispatch({ 
        type: dataConstants.GET_COURSE_DETAILS_FAILURE, 
        payload: { error: error?.response?.data }
      });
    }
  }
}

export const getAlbums = (page, limit, search) => {
  return async dispatch => {
    try{
      dispatch({ type: dataConstants.GET_ALBUMS_REQUEST });  
      let URL = `/albums?pagination[page]=${page}&pagination[pageSize]=${limit}&populate=deep&sort=createdAt:desc`    
      if(search) URL += `&filters[title][$contains]=${search}`;
      const { data } = await Axios.get(URL);      
      dispatch({ 
        type: dataConstants.GET_ALBUMS_SUCCESS,
        payload: data
      });            
    }catch(error){      
      dispatch({ 
        type: dataConstants.GET_ALBUMS_FAILURE, 
        payload: { error: error?.response?.data }
      });
    }
  }
}

export const getAlbumDetails = (slug) => {
  return async dispatch => {
    try{
      dispatch({ type: dataConstants.GET_ALBUM_DETAILS_REQUEST });      
      const { data } = await Axios.get(`/albums?filters[slug][$eq]=${slug}&populate=deep`);      
      dispatch({ 
        type: dataConstants.GET_ALBUM_DETAILS_SUCCESS,
        payload: data?.data?.[0]
      });            
    }catch(error){      
      dispatch({ 
        type: dataConstants.GET_ALBUM_DETAILS_FAILURE, 
        payload: { error: error?.response?.data }
      });
    }
  }
}

export const getSyllabus = (page, limit, search) => {
  return async dispatch => {
    try{
      dispatch({ type: dataConstants.GET_SYLLABUS_REQUEST });  
      let URL = `/syllabi?pagination[page]=${page}&pagination[pageSize]=${limit}&populate=deep&sort=createdAt:desc`          
      if(search) URL += `&filters[title][$contains]=${search}`;
      const { data } = await Axios.get(URL);      
      dispatch({ 
        type: dataConstants.GET_SYLLABUS_SUCCESS,
        payload: data
      });            
    }catch(error){      
      dispatch({ 
        type: dataConstants.GET_SYLLABUS_FAILURE, 
        payload: { error: error?.response?.data }
      });
    }
  }
}

export const getResults = (page, limit, search) => {
  return async dispatch => {
    try{
      dispatch({ type: dataConstants.GET_RESULTS_REQUEST });  
      let URL = `/results?pagination[page]=${page}&pagination[pageSize]=${limit}&populate=deep&sort=createdAt:desc`          
      if(search) URL += `&filters[title][$contains]=${search}`;
      const { data } = await Axios.get(URL);      
      dispatch({ 
        type: dataConstants.GET_RESULTS_SUCCESS,
        payload: data
      });            
    }catch(error){      
      dispatch({ 
        type: dataConstants.GET_RESULTS_FAILURE, 
        payload: { error: error?.response?.data }
      });
    }
  }
}

export const getExamSchedules = (page, limit, search) => {
  return async dispatch => {
    try{
      dispatch({ type: dataConstants.GET_EXAM_SCHEDULE_REQUEST });  
      let URL = `/exam-schedules?pagination[page]=${page}&pagination[pageSize]=${limit}&populate=deep&sort=createdAt:desc`          
      if(search) URL += `&filters[title][$contains]=${search}`;
      const { data } = await Axios.get(URL);      
      dispatch({ 
        type: dataConstants.GET_EXAM_SCHEDULE_SUCCESS,
        payload: data
      });            
    }catch(error){      
      dispatch({ 
        type: dataConstants.GET_EXAM_SCHEDULE_FAILURE, 
        payload: { error: error?.response?.data }
      });
    }
  }
}

export const getFaculty = (
  page,
  limit,
  search,
  type="faculty"
) => {
  return async (dispatch) => {
    try{      
      dispatch({ type: dataConstants.GET_FACULTY_REQUEST })
      let URL = `/faculties?pagination[page]=${page}&pagination[pageSize]=${limit}&populate=deep&filters[type][$eq]=${type}&sort=position:asc`
      if(search) URL += `&filters[name][$contains]=${search}`;      
      const { data } = await Axios.get(URL);                        
      if(data){       
        dispatch({ 
          type: dataConstants.GET_FACULTY_SUCCESS,
          payload: {
            meta: data?.meta,
            data: data?.data,
          }
        })
      }
    }catch(error){           
      dispatch({
        type: dataConstants.GET_FACULTY_FAILURE,
        payload: { error: error?.response?.data }
      })
    }
  }
}