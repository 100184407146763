import { dataConstants } from "redux/constants"

const initState = {      
  error: null,  
  loading: false,  
  message: null,     
  about: null,
  events: null,
  event: null,
  courses: null,
  course: null,
  albums: null,
  album: null,
  syllabus: null,
  examSchedule: null,
  results: null,
  faculty: null,  
}

export const dataReducer = (state = initState, action) => {
  switch(action.type){    
    case dataConstants.GET_ABOUT_PAGE_DATA_REQUEST:
    case dataConstants.GET_EVENTS_REQUEST:
    case dataConstants.GET_EVENT_DETAILS_REQUEST:
    case dataConstants.GET_COURSES_REQUEST:
    case dataConstants.GET_COURSE_DETAILS_REQUEST:
    case dataConstants.GET_ALBUMS_REQUEST:
    case dataConstants.GET_ALBUM_DETAILS_REQUEST:
    case dataConstants.GET_SYLLABUS_REQUEST:
    case dataConstants.GET_RESULTS_REQUEST:
    case dataConstants.GET_EXAM_SCHEDULE_REQUEST:
    case dataConstants.GET_FACULTY_REQUEST:
      return {
        ...state,
        loading: true,
      }

    case dataConstants.GET_ABOUT_PAGE_DATA_SUCCESS:
      return {
        ...state,
        about: action.payload,
        loading: false,
      }

    case dataConstants.GET_EVENTS_SUCCESS:
      return {
        ...state,
        events: action.payload,
        loading: false,
      }

    case dataConstants.GET_EVENT_DETAILS_SUCCESS:
      return {
        ...state,
        event: action.payload,
        loading: false,
      }

    case dataConstants.GET_COURSES_SUCCESS:
      return {
        ...state,
        courses: action.payload,
        loading: false,
      }

    case dataConstants.GET_COURSE_DETAILS_SUCCESS:
      return {
        ...state,
        course: action.payload,
        loading: false,
      }

    case dataConstants.GET_ALBUMS_SUCCESS:
      return {
        ...state,
        albums: action.payload,
        loading: false,
      }

    case dataConstants.GET_ALBUM_DETAILS_SUCCESS:
      return {
        ...state,
        album: action.payload,
        loading: false,
      }

    case dataConstants.GET_SYLLABUS_SUCCESS:
      return {
        ...state,
        syllabus: action.payload,
        loading: false,
      }

    case dataConstants.GET_RESULTS_SUCCESS:
      return {
        ...state,
        results: action.payload,
        loading: false,
      }

    case dataConstants.GET_EXAM_SCHEDULE_SUCCESS:
      return {
        ...state,
        examSchedule: action.payload,
        loading: false,
      }

    case dataConstants.GET_FACULTY_SUCCESS:
      return {
        ...state,
        loading: false,
        faculty: action.payload,
      }
    
    case dataConstants.GET_ABOUT_PAGE_DATA_FAILURE:
    case dataConstants.GET_EVENTS_FAILURE:
    case dataConstants.GET_EVENT_DETAILS_FAILURE:
    case dataConstants.GET_COURSES_FAILURE:
    case dataConstants.GET_COURSE_DETAILS_FAILURE:
    case dataConstants.GET_ALBUMS_FAILURE:
    case dataConstants.GET_ALBUM_DETAILS_FAILURE:
    case dataConstants.GET_SYLLABUS_FAILURE:
    case dataConstants.GET_RESULTS_FAILURE:
    case dataConstants.GET_EXAM_SCHEDULE_FAILURE:
    case dataConstants.GET_FACULTY_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload.error,
      }

    case dataConstants.RESET_DATA:
      return {
        ...state,
        loading: false,
        error: null,
        message: null,        
      }

    case dataConstants.RESET:
      return initState;

    default: return state;
  }  
}