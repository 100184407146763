import React from "react";

import { Box, Flex, Grid, Text } from "atoms";

export const VisionMission = ({
  id,
  motto,
  vision,
  mission
}) => {
  return (
    <Box
      id={id}
      mt="6rem"
      bg="#f3f3f3"  
      p={{xs: "6rem 3rem", md: "6rem"}}           
    >  
      <Text
        as="h3"
        fontSize={{xs: "3rem", md: "3.6rem"}}
        fontWeight="800"
        mb="3rem"
        color="primary.500"
      >
        Why Choose Us?
      </Text>        
      <Grid
        gridTemplateColumns={{xs: "1fr", lg: "0.8fr 1fr 1fr"}}
        gridGap="2.4rem"                
      >
        {motto && <PopluateTitleValue
          title={motto?.title}
          value={motto?.value}
        />} 
        {vision && <PopluateTitleValue
          title={vision?.title}
          value={vision?.value}
        />} 
        {mission && <PopluateTitleValue
          title={mission?.title}
          value={mission?.value}
        />} 
      </Grid>                  
    </Box> 
  )
}

const PopluateTitleValue = ({
  title,
  value
}) => (
  <Box>
    <Flex                    
      alignItems="center"
      mb="1rem"
    >
      <Box
        width="1rem"
        height="1rem"
        mr="1rem"
        borderRadius="50%"
        bg="secondary.500"                      
      />
      <Text
        as="h4"        
        fontSize="2rem"
        fontWeight="800"                      
        color="primary.500"        
      >
        {title}
      </Text>
    </Flex>
    {Array.isArray(value) ? <Box>
      {value.map((item, index) => (        
        <Text          
          key={index}
          as="p"
          pl="2rem"          
          fontSize="1.6rem"
          fontWeight="500"                    
          color="#707070"
          mb="1rem"
        >
          {index+1}. {item}
        </Text>        
      ))}
    </Box> : <Text
      as="p"
      pl="2rem"
      fontSize="1.6rem"
      fontWeight="500"                    
      color="#707070"
      className="text-justify"
    >
      {value}
    </Text>}
  </Box>
)

