import React from "react";
import { MdCall } from "react-icons/md";
import { MdOutlineMail } from "react-icons/md";

import { Box, Button, Flex, Image, Text } from "atoms";
import { GetLink } from "molecules/GetLink";

export const ProfileCard = ({
  name,
  designation,
  qualification,
  specification,
  email,
  phone,
  image,
  profileUrl
}) => {
  return (
    <Box
      boxShadow="0px 4px 4px rgba(0, 0, 0, 0.25)"
      p="1.5rem"
      borderRadius="0.4rem"
    >
      <Flex
        flexDirection={{xs: "column", md: "row"}}
        style={{gap: "1.4rem"}}
      >
        <Flex
          flex={0.5}
          flexDirection="column"
          alignItems="center"
          justifyContent="center"
          style={{gap: "0.6rem"}}
        >
          {image && <Box
            width={{ xs: "24rem", md: "100%"}}
            height={{ xs: "24rem", md: "unset" }}
            borderRadius={{ xs: "50%", md: "0.4rem" }}            
            overflow="hidden"
          >
            <Image
              src={image}
              alt={name}
              width="100%"
              height="100%"               
              objectFit="cover"
            />
          </Box>}
          {profileUrl && <GetLink
            url={profileUrl}
          >
            <Button
              width="fit-content"
              variant="secondary"
              p="0.5rem 1rem"
              borderRadius="0.4rem"
            >
              View Profile
            </Button>
          </GetLink>}
        </Flex>
        <Flex
          flex={1}
          flexDirection="column"
          style={{gap: "0.5rem"}}
          textAlign={{xs: "center", md: "left"}}
        >
          {name && <Text
            fontSize="1.8rem"
            fontWeight="700"
          >
            {name}
          </Text>}
          {designation && <Text
            fontSize="1.6rem"
            fontWeight="600"
            color="secondary.500"
          >
            {designation}
          </Text>}
          {qualification && <Text
            fontSize="1.4rem"
            fontWeight="600"
            color="grey.500"
          >
            {qualification}
          </Text>}
          {specification && <Text
            fontSize="1.4rem"
            fontWeight="600"
            color="grey.500"
          >
            {specification}
          </Text>}
          {email && <LinkOption
            Icon={<MdOutlineMail 
              fontSize="1.8rem"
            />}
            label={email}
            url={`mailto:${email}`}
          />}           
          {phone && <LinkOption
            Icon={<MdCall
              fontSize="1.8rem"
            />}
            label={phone}
            url={`tel:${phone}`}
          />}        
        </Flex>
      </Flex>
    </Box>
  )
}

const LinkOption = ({
  Icon,
  label,
  url
}) => (
  <GetLink
    url={url}
  >
    <Flex
      alignItems="center"
      style={{gap: "0.5rem"}}
      color="secondary.500"
      fontSize="1.4rem"
      justifyContent={{xs: "center", md: "flex-start"}}
    >
      {Icon}
      <Text
        fontWeight="600"
      >
        {label}
      </Text>
    </Flex>
  </GetLink>
)