import React, { createRef } from "react";
import { FiArrowLeft, FiArrowRight } from "react-icons/fi";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import { Box, Flex, Grid, Image, Text } from "atoms";
import { getStrapiMediaUrl } from "utils/utilities";

export const HomeTestimonials = ({
  testimonials
}) => {

  const sliderRef = createRef();  

  const settings = {        
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,    
    arrows: false,
    autoplay: true,
    autoplaySpeed: 5000,
    dots: false,
  };

  return (
    <Box
      py={{xs: "8rem", lg: "10rem"}}          
      px={{xs: "3rem", md: "6rem"}}
      bg="primary.500"
      as="section"
    >
      <Grid
        gridTemplateColumns={{xs: "1fr", xm: "1fr 1fr", lg: "1.5fr 1fr"}}
        gridGap={{xs: "2.4rem", md: "4.8rem"}}        
        maxWidth={{xs: "100%", lg: "120rem"}}
        mx="auto"        
      >
        <Box
          px="1.2rem"
          width="100%"
        >
          <Text
            as="h2"
            fontSize={{xs: "5rem", md: "6rem"}}
            lineHeight={{xs: "6rem", md: "7rem"}}
            fontWeight="800"
            color="white"             
          >
            Student Say<br/>
            About Us.
          </Text>

          <Flex
            style={{
              gap: "1.5rem"
            }}
            mt={{xs: "2rem", md: "3rem"}}
          >
            <Flex
              border="2px solid"
              borderColor="#d4d7dc"
              width="5rem"
              height="5rem"
              borderRadius="50%"
              justifyContent="center"
              alignItems="center"
              fontSize="2rem"
              color="#d4d7dc"
              cursor="pointer"
              transition="all 0.3s ease"
              _hover={{
                color: "white",
                borderColor: "white",
                scale: "1.1"
              }}
              onClick={() => sliderRef.current.slickPrev()}
            >
              <FiArrowLeft/>
            </Flex>
            <Flex
              border="2px solid"
              borderColor="#d4d7dc"
              width="5rem"
              height="5rem"
              borderRadius="50%"
              justifyContent="center"
              alignItems="center"
              fontSize="2rem"
              color="#d4d7dc"
              cursor="pointer"
              transition="all 0.3s ease"
              _hover={{
                color: "white",
                borderColor: "white",
                scale: "1.1"
              }}
              onClick={() => sliderRef.current.slickNext()}
            >
              <FiArrowRight/>
            </Flex>
          </Flex>
        </Box>

        <Box
          maxWidth={{xs: "92vw", md: "80vw", xm: "42vw", lg: "56rem"}}                     
        >
          <Slider
            ref={sliderRef}
            {...settings}                
          >
            {testimonials?.map((item) => (
              <Box    
                key={item?.id}
              >
                <TestimonialCard
                  name={item?.username}
                  course={item?.designation}
                  comment={item?.comment}
                  image={getStrapiMediaUrl(item?.avatar?.url)}
                />
              </Box>
            ))}
          </Slider>          
        </Box>
      </Grid>
    </Box>
  )
}

const TestimonialCard = ({ 
  image, 
  name, 
  course, 
  comment 
}) => (
  <Box>
    <Text
      as="p"
      fontSize="1.6rem"
      color="white"
      opacity="0.8"
      lineHeight="2.4rem"
    >
      {comment}
    </Text>
    <Flex
      style={{
        gap: "1.5rem"
      }}
      alignItems="center"
      mt="3rem"
    >
      <Box
        width="6rem"
        height="6rem"
        borderRadius="50%"
        overflow="hidden"
      >
        <Image
          src={image}
          alt={name}
          width="100%"
          height="100%"
          objectFit="cover"
          borderRadius="50%" 
        />
      </Box>
      <Box>
        <Text
          as="h3"
          fontSize="1.8rem"
          color="white"
          fontWeight="800"
        >
          {name}
        </Text>
        <Text
          as="p"
          fontSize="1.6rem"
          color="white"
          opacity="0.8"
        >
          {course}
        </Text>
      </Box>
    </Flex>
  </Box>
)