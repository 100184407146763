import React, { useEffect } from "react";

import SEO from "seo/SEO";
import { EventsPage } from "templates";
import { scrollToTop } from "utils/utilities";

const Events = () => {

  useEffect(() => {
    scrollToTop(window)  
  }, [])

  return (
    <>
      <SEO
        title="Events | Vasudev College of Pharmacy Haldwani"
        description="Vasudev College of Pharmacy is one of the best pharmacy college in Haldwani, Nainital. We offer B.Pharma and D.Pharma courses in Haldwani, Nainital."
        currentURL={`${process.env.REACT_APP_PUBLIC_URL}/events`}
        keywords="Vasudev College of Pharmacy, Pharmacy College in Haldwani, Pharmacy College in Nainital, B.Pharma College in Haldwani, B.Pharma College in Nainital, D.Pharma College in Haldwani, D.Pharma College in Nainital"
      />
      <EventsPage/>
    </>
  )
}

export default Events;