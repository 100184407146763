import React, { useEffect, useState } from "react";
import { MdKeyboardDoubleArrowUp } from "react-icons/md";
import { useDispatch, useSelector } from "react-redux";
import { BrowserRouter as Router } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import { ThemeProvider } from "styled-components";
import "react-toastify/dist/ReactToastify.css";

import { Box } from "atoms";
import { InitialLoader } from "molecules";
import { Layout } from "organisms";
import { getInitialData } from "redux/actions";
import { AppRoutes } from "routes";
import { theme } from "styles";
import { scrollToTop } from "utils/utilities";
import "styles/globals.css";
import "styles/style.css";

function App() {
  
  const [scroll, setScroll] = useState(false); 
  const dispatch = useDispatch();
  const { initialLoading } = useSelector(state => state.app);

  const handleScroll = () => {
    if (window.scrollY >= 200) {
      setScroll(true);
    } else {
      setScroll(false);
    }
  };

  useEffect(() => {    
    dispatch(getInitialData());
  }, [dispatch]);

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  });
  
  return (    
    <ThemeProvider theme={theme}>                    
      <ToastContainer />        
      {initialLoading ?
        <InitialLoader/>
      : <Router>
        <Layout>
          <AppRoutes/>
          {scroll && (
            <Box
              position="fixed"
              color="white"
              bg="secondary.500"
              bottom="4rem"
              right="2rem"
              width="4rem"
              height="4rem"
              borderRadius="50%"
              display="flex"
              alignItems="center"
              justifyContent="center"
              cursor="pointer"
              onClick={() => scrollToTop(window)}
              zIndex="10"
            >
              <MdKeyboardDoubleArrowUp size="2rem" />
            </Box>
          )}         
        </Layout>            
      </Router>}           
    </ThemeProvider>    
  );
}

export default App;
