import React from "react";
import { useSelector } from "react-redux";

import { Box, Grid } from "atoms";
import { Contact, ContactForm } from "molecules";

export const HomeContact = () => {

  const { footer } = useSelector(state => state?.app)

  return (
    <Box
      py={{xs: "8rem", lg: "10rem"}}          
      px={{xs: "3rem", md: "6rem"}}
      bg="white"
      as="section"
    >
      <Grid
        gridTemplateColumns={{xs: "1fr", lg: "1fr 1fr"}}
        gridGap="2.4rem"                
      >
        <Contact
          title="Let's Contact Us"
          description="We are always ready to help you. There are many ways to contact us. You may drop us a line, give us a call or send an email, choose what suits you the most."
          mobile={footer?.phone?.split("<br/>")}
          email={footer?.email?.split("<br/>")}
          address={footer?.address}
        />
        <ContactForm/>
      </Grid>
    </Box>
  )
}