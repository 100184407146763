import React from "react";
import { useSelector } from "react-redux";

import { Box } from "atoms";
import { PageHero } from "molecules";
import { About, Message, VisionMission } from "./molecules";
import { getStrapiMediaUrl } from "utils/utilities";

export const AboutUsPage = () => {  

  const { about } = useSelector(state => state.data);

  return (
    <Box 
      id="about"      
    >
      {/* find __component: "common.page-hero" in about and render PageHero */}      
      {about?.find(item => item.__component === "common.page-hero") && 
        <PageHero
          image={getStrapiMediaUrl(about?.find(item => item.__component === "common.page-hero")?.image?.data?.attributes?.url)}
          title={about?.find(item => item.__component === "common.page-hero")?.title}
          breadcrumbs={[
            {
              title: "Home",
              url: "/"
            },
            {
              title: "About Us",
              url: "/about-us"
            }
          ]}
        />
      }

      <Box
        bg="white"                
        py={{xs: "8rem", lg: "10rem"}}
        as="section"
      >
        {about?.map((item) => {
           switch (item?.__component) {
            case "section.about":
              return (
                <About          
                  key={item?.id}
                  title={item?.about?.title}
                  description= {item?.about?.description}
                  images={item?.images?.data?.map(image => getStrapiMediaUrl(image?.attributes?.url))}
                />
              )

            case "section.about-mmv":
              return (
                <VisionMission
                  key={item?.id}
                  id={item?.docId}
                  motto={{
                    title: item?.motto?.title,
                    value: item?.motto?.description
                  }}
                  vision={{
                    title: item?.vision?.title,
                    value: item?.vision?.description
                  }}
                  mission={{
                    title: item?.mission?.title,
                    value: item?.mission?.description?.split("<br/>")
                  }}
                />
              )

            case "common.message":
              return (
                <Message
                  key={item?.id}
                  id={item?.docId}
                  title={item?.title}
                  name={item?.name}
                  image={getStrapiMediaUrl(item?.avatar?.data?.attributes?.url)}
                  message={item?.message?.split("<br/>")}
                  wish={item?.wish}
                  imgFirst={item?.imgFirst}
                />
              )

            default:
              return null;
          }
        })}                                    
      </Box>
    </Box>
  )
}