import React, { useEffect } from "react";

import SEO from "seo/SEO";
import { SyllabusPage } from "templates";
import { scrollToTop } from "utils/utilities";

export const Syllabus = () => {

  useEffect(() => {
    scrollToTop(window)  
  }, [])

  return (
    <>   
      <SEO
        title="Syllabus | Vasudev College of Pharmacy Haldwani"
        description="Welcome to Vasudev College Of Pharmacy Vasudev College of Pharmacy is the first Pharmacy College in Nainital District of Uttarakhand"
        currentURL={`${process.env.REACT_APP_PUBLIC_URL}/student/syllabus`}
        keywords="Vasudev College of Pharmacy, Pharmacy College in Haldwani, Pharmacy College in Nainital, B.Pharma College in Haldwani, B.Pharma College in Nainital, D.Pharma College in Haldwani, D.Pharma College in Nainital"
      />   
      <SyllabusPage/>      
    </>
  );
};

export default Syllabus;