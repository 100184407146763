import React, { useEffect } from "react";

import SEO from "seo/SEO";
import { PrivacyPolicyPage } from "templates";
import { scrollToTop } from "utils/utilities";

const PrivacyPolicy = () => {  
  useEffect(() => {
    scrollToTop(window)  
  }, [])

  return (
    <>     
      <SEO
        title="Privacy Policy | Vasudev College of Pharmacy Haldwani"
        description="Vasudev College of Pharmacy is one of the best pharmacy college in Haldwani, Nainital. We offer B.Pharma and D.Pharma courses in Haldwani, Nainital."
        currentURL={`${process.env.REACT_APP_PUBLIC_URL}/privacy-policy`}        
      /> 
      <PrivacyPolicyPage/>      
    </>
  );  
}

export default PrivacyPolicy;