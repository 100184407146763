import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { AiOutlineClose } from "react-icons/ai";
import { FaSearch } from "react-icons/fa";
import { RiMenu3Fill } from "react-icons/ri";
import { Link } from "react-router-dom";

import { Box, Button, Flex, Image, Text } from "atoms";
import { GetLink, HeaderOption } from "molecules";
import { getStrapiMediaUrl } from "utils/utilities";

export const Header = () => {

  const [ mobileMenu, setMobileMenu ] = useState(false)
  const [scroll, setScroll] = useState(false);    

  const { header } = useSelector(state => state.app);

  const handleScroll = () => {
    if (window.scrollY >= 100) {
      setScroll(true);
    } else {
      setScroll(false);
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  });

  return (
    <>
      <Flex        
        position={scroll ? "fixed" : "relative"}        
        top="0"
        left="0"        
        width="100%"
        bg="primary.500"
        p="1.2rem"  
        alignItems="center"
        justifyContent="space-between"      
        style={{
          gap: "1.2rem"
        }}
        zIndex="10"
      >
        <Link
          to="/"
        >
          <Flex 
            alignItems="center"         
            style={{
              gap: "1.2rem"
            }}
          >
            {header?.logo && <Box
              width="5.6rem"
              height="5.6rem"
            >
              <Image
                src={getStrapiMediaUrl(header?.logo?.url)}
                alt="Logo"
                width="100%"
                height="100%"
              />
            </Box>}
            <Text
              fontSize="2rem"
              fontWeight="bold"
              color="white"
              lineHeight="2.2rem"
              letterSpacing="0.12rem"            
            >
              Vasudev College of Pharmacy<br/>
              <Text
                as="span"
                fontSize="1.5rem"
                fontWeight="bold"
              >
                Lamachaur Haldwani
              </Text>
            </Text>
          </Flex>
        </Link>

        <Box
          display={{xs: "none", lg: "flex"}}
          alignItems="center"
          style={{
            gap: "2rem"
          }}
        >
          {header?.nav?.map((item) => (
            <HeaderOption
              key={item?.id}
              title={item?.title}
              url={item?.url || ""}
              children={item?.children || []}
              setMobileMenu={setMobileMenu}
              type={item?.type || ""}
            />
          ))}          
        </Box>

        <Box          
          style={{
            gap: "1.2rem"
          }}
          alignItems="center"
          display={{xs: "none", lg: "flex"}}
        >
          <Button
            variant="secondary"
            borderRadius="50%"
            width="5rem"
            height="5rem"
          >
            <Flex              
              alignItems="center"
              justifyContent="center"              
            >
              <FaSearch                
                size="1.6rem"
              />
            </Flex>
          </Button>

          <Box
            width="fit-content"            
          >
            <GetLink
              url="/contact-us"
            >            
              <Button
                variant="secondary"
                borderRadius="5rem"
                p="1.5rem 3rem"
                width="fit-content"
              >
                Contact Us
              </Button>
            </GetLink>
          </Box>
        </Box>
        
        <Box display={{xs: "block", lg: "none"}}>
          <Button
            variant="secondary"
            borderRadius="50%"
            width="5rem"
            height="5rem"  
            onClick={() => setMobileMenu(!mobileMenu)}        
          >
            <Flex              
              alignItems="center"
              justifyContent="center"              
            >
              <RiMenu3Fill                
                size="1.6rem"
              />
            </Flex>
          </Button>
        </Box>
      </Flex>

      <Box
        display={mobileMenu ? {xs: "block", lg: "none"} : "none"}
        height="100vh"
        maxHeight="100vh"
        overflowY="auto"
        width="100%"
        bg="primary.500"
        p="1.2rem"
        position="fixed"
        top="0"
        left="0"
        zIndex="20"
        className="hide-scrollbar"
      >
        <Flex        
          width="100%"
          alignItems="center"
          justifyContent="space-between"      
          style={{
            gap: "1.2rem"
          }}          
        >
          <Flex 
            alignItems="center"         
            style={{
              gap: "1.2rem"
            }}
          >
            {header?.logo && <Box
              width="5rem"
              height="5rem"
            >
              <Image
                src={getStrapiMediaUrl(header?.logo?.url)}
                alt="Logo"
                width="100%"
                height="100%"
              />
            </Box>}
            <Text
              fontSize="2rem"
              fontWeight="bold"
              color="white"
              lineHeight="2.4rem"
              letterSpacing="0.12rem"            
            >
              Vasudev College of <br/>
              Pharmacy
            </Text>
          </Flex>
          <Box >
            <Button
              variant="secondary"
              borderRadius="50%"
              width="5rem"
              height="5rem"  
              onClick={() => setMobileMenu(!mobileMenu)}        
            >
              <Flex              
                alignItems="center"
                justifyContent="center"              
              >
                <AiOutlineClose                
                  size="1.6rem"
                />
              </Flex>
            </Button>
          </Box>
        </Flex>      

        <Box
          mt="2.8rem"          
        >
          {header?.nav?.map((item) => (
            <HeaderOption
              key={item?.id}
              title={item?.title}
              url={item?.url || ""}
              children={item?.children || []}
              setMobileMenu={setMobileMenu}              
              type={item?.type || ""}
            />
          ))}          
        </Box>  
      </Box>
    </>
  );
};
