import React from "react";
import ImageGallery from "react-image-gallery";
import { useSelector } from "react-redux";
import "react-image-gallery/styles/css/image-gallery.css";

import { Box, Text } from "atoms";
import { PageHero } from "molecules";
import { getStrapiMediaUrl } from "utils/utilities";

export const GalleryDetailsPage = () => {

  const { album, loading } = useSelector(state => state.data);

  return (
    <>    
      <PageHero
        image={getStrapiMediaUrl(album?.attributes?.thumbnail?.data?.attributes?.url)}
        title="Album Details"
        breadcrumbs={[
          {
            title: "Home",
            url: "/"
          },
          {
            title: "Albums",
            url: "/gallery"
          },
          {
            title: (album && !loading) ? album?.attributes?.title : "Loading...",
            url: "#"
          }
        ]}
      />
      <Box
        bg="white"                
        p={{xs: "8rem 3rem", lg: "10rem 3rem"}}
        as="section"
        maxWidth={{xs: "100%", lg: "120rem"}}
        mx="auto"
      >   
        {album?.attributes?.title && <Text
          as="h2"
          fontSize={{xs: "3rem", md: "3.6rem"}}
          fontWeight="800"
          mb="2rem"
          color="primary.500"
        >
          {album?.attributes?.title}
        </Text>}
        {/* <PhotoAlbum 
          layout="rows" 
          photos={album?.attributes?.images?.data?.map((item) => ({
            src: getStrapiMediaUrl(item?.attributes?.url),
            width: item?.attributes?.width,
            height: item?.attributes?.height,
          }))}
        />                  */}
        <Box maxWidth="100%">                  
          {album?.attributes?.images?.data && <ImageGallery
            items={album?.attributes?.images?.data?.map((item) => ({
              original: getStrapiMediaUrl(item?.attributes?.url),
              thumbnail: getStrapiMediaUrl(item?.attributes?.url),              
            }))}
          />}                
        </Box>
      </Box>
    </>
  )
}