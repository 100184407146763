import React from "react";

import { Box, Flex, Grid, Image, Text } from "atoms";
import { GetLink } from "molecules";

const nav = [
  { title: "About Us", id: "#about" },
  { title: "Vision & Mission", id: "#vision-mission" },
  { title: "Chairman's Message", id: "#chairman-message" },
  { title: "Director's Message", id: "#director-message" },            
]

export const About = ({  
  title,
  description,
  images,    
}) => {
  return (
    <Box       
      px={{xs: "3rem", md: "6rem"}}
    >
      <Grid
        gridTemplateColumns={{xs: "1fr", xm: "2fr 1fr", lg: "1.8fr 1fr"}}
        gridGap={{xs: "2.4rem", xm: "1.2rem", lg: "2.4rem"}}       
      >
        <Box>
          <Box
            id="about"          
          >
            {title && <Text
              as="h2"
              fontSize={{xs: "3rem", md: "3.6rem"}}
              fontWeight="800"
              mb="2rem"
              color="primary.500"
            >
              {title}
            </Text>}
            {description && <Text
              as="p"
              fontSize="1.6rem"
              fontWeight="500"              
              color="#707070"
              className="text-justify"           
            >        
              {description}      
            </Text>}

            {images?.length && <Flex
              my="2rem"
              flexWrap="wrap"
              style={{ gap: "1.5rem" }}
            >
              {images?.map((image, index) => (
                <Box
                  key={index}                  
                  height={{xs: "24rem", md: "19rem", xm: "17rem", lg: "24rem"}}
                  mt="2rem"
                >
                  <Image
                    src={image}
                    alt="about"
                    width="100%"
                    height="100%"                    
                  />
                </Box>  
              ))}
            </Flex>}                       
          </Box>
        </Box>

        <Box
          display={{xs: "none", xm: "block"}}
        >
          <Box
            p="3rem"
            mb="3rem"
            bg="#f3f3f3"
          >
            <Text
              as="h3"
              fontSize="2.2rem"
              fontWeight="800"
              mb="2rem"
              color="primary.500"
            >
              Content List
            </Text>  
            {nav?.map((item, index) => (
              <Box
                key={index}
                mb="1rem"
                width="fit-content"
              >
                <GetLink
                  url={item?.id}
                  type="hash"                               
                >
                  <Text                    
                    fontSize="1.6rem"
                    fontWeight="500"
                    color="primary.500"
                    _hover={{
                      color: "secondary.500"
                    }}
                    transition="all 0.3s ease"
                  >
                    {item?.title}
                  </Text>
                </GetLink>
              </Box>
            ))}          
          </Box>
        </Box>
      </Grid>
    </Box>   
  )
}