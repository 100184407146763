import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { Grid } from "atoms";
import { AlbumCard, PageHero, TitleSearchLayout } from "molecules";
import { getAlbums } from "redux/actions";
import { getStrapiMediaUrl } from "utils/utilities";

export const GalleryPage = () => {

  const [ search, setSearch ] = useState("");
  const [ page, setPage ] = useState(1);

  const dispatch = useDispatch();
  const { albums } = useSelector(state => state.data);

  useEffect(() => {
    dispatch(getAlbums(page, 12, search));
  }, [search, page, dispatch]);

  return (
    <>
      <PageHero
        image="/assets/page-hero.jpg"
        title="Gallery"
        breadcrumbs={[
          {
            title: "Home",
            url: "/"
          },
          {
            title: "Gallery",
            url: "/albums"
          }
        ]}
      />
      <TitleSearchLayout
        title="College Albums"
        search={search}
        setSearch={setSearch}
        totalCount={albums?.meta?.pagination?.total}
        resultPerPage={albums?.meta?.pagination?.pageSize}
        page={page}
        setPage={setPage}
        notFound="No albums found"
      >        
        <Grid
          gridTemplateColumns={{
            xs: "1fr", 
            md: `${albums?.data?.length > 1 ? "repeat(2, 1fr)" : `repeat(${albums?.data?.length}, minmax(0, 38rem))`}}`,
            xm: `${albums?.data?.length > 2 ? "repeat(3, 1fr)" : `repeat(${albums?.data?.length}, minmax(0, 31rem))`}`,
            lg: `${albums?.data?.length > 2 ? "repeat(3, 1fr)" : `repeat(${albums?.data?.length}, minmax(0, 32rem))`}`
          }}
          justifyContent="center"
          gridGap="1.5rem"          
          mt="4rem"        
        >
          {albums?.data?.map((item) => (
            <AlbumCard
              key={item?.id}
              id={item?.id}
              slug={item?.attributes?.slug}
              image={getStrapiMediaUrl(item?.attributes?.thumbnail?.data?.attributes?.url)}
              title={item?.attributes?.title}
              no={item?.attributes?.images?.data?.length}
            />
          ))}
        </Grid>          
      </TitleSearchLayout>
    </>
  )
}