import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { Grid } from "atoms";
import { EventCard, PageHero, TitleSearchLayout } from "molecules";
import { getEvents } from "redux/actions";
import { extractDate, getStrapiMediaUrl } from "utils/utilities";

export const EventsPage = () => {

  const [ search, setSearch ] = useState("");
  const [ page, setPage ] = useState(1);

  const dispatch = useDispatch();
  const { events } = useSelector(state => state.data);

  useEffect(() => {
    dispatch(getEvents(page, 12, search));
  }, [search, page, dispatch]);

  return (
    <>
      <PageHero
        image="/assets/page-hero.jpg"
        title="Events"
        breadcrumbs={[
          {
            title: "Home",
            url: "/"
          },
          {
            title: "Events",
            url: "/events"
          }
        ]}
      />
      <TitleSearchLayout
        title="College Events"
        search={search}
        setSearch={setSearch}
        totalCount={events?.meta?.pagination?.total}
        resultPerPage={events?.meta?.pagination?.pageSize}
        page={page}
        setPage={setPage}
        notFound="No events found"
      >        
        <Grid
          gridTemplateColumns={{
            xs: "1fr", 
            md: `${events?.data?.length > 1 ? "repeat(2, 1fr)" : `repeat(${events?.data?.length}, minmax(0, 48rem))`}}`,
            lg: `${events?.data?.length > 2 ? "repeat(3, 1fr)" : `repeat(${events?.data?.length}, minmax(0, 40rem))`}`
          }}
          justifyContent="center"
          gridGap="1.5rem"
          mt="4rem"        
        >
          {events?.data?.map((item) => (
            <EventCard
              key={item.id}
              image={getStrapiMediaUrl(item?.attributes?.image?.data?.attributes?.url)}
              title={item?.attributes?.title}              
              date={extractDate(item?.attributes?.createdAt)}             
              slug={item?.attributes?.slug}
            />
          ))}
        </Grid>
      </TitleSearchLayout>         
    </>
  )
}