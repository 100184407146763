import React from "react";
import { ErrorMessage, Field } from "formik";

import Box from "../Box";
import { Error } from "../Error";
import { Flex } from "../Flex";
import Text from "../Text";

export const Input = ({
  name,
  type,  
  placeholder,
  id,
  icon,
  iconColor,
  touched,
  errors,
  textArea,
  rows,
  disabled=false,
  lessMB,
  label,  
}) => {
  return (
    <>
      {label && <Text
        as="label"
        htmlFor={id}
        fontSize="1.4rem"
        fontWeight="400"
        color="primary.500"         
      >
        {label}
      </Text>}
      <Box mb={lessMB ? "0.8rem" : "2rem"} mt="0.7rem">        
        {icon && <Flex
          position="absolute"
          left="1rem"          
          height="4rem"
          alignItems="center"
          justifyContent="center"
          fontSize="1.4rem"           
          color={touched && !errors ? "accent.500" : iconColor}                   
        >
          {icon}
        </Flex>}
        {textArea ? <Field
          as="textarea"
          type={type}
          id={id}
          name={name}
          placeholder={placeholder}
          rows={rows}
          disabled={disabled}
          className={
            touched && errors
              ? "input-error"
              : ""
          }        
          style={{
            borderColor: touched && !errors
              ? "#0BBA7A"
              : ""            
          }}
        />      
        : <Field
          type={type}
          id={id}
          name={name}
          placeholder={placeholder}
          onWheel={(e) => e.target.blur()}
          disabled={disabled}        
          className={
            touched && errors
              ? "input-error"
              : ""
          }        
          style={{
            borderColor: touched && !errors
              ? "#0BBA7A"
              : ""
          }}
        />}
        <ErrorMessage name={name} component={Error} />
      </Box>
    </>
  )
}