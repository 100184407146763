import React, { useEffect } from "react";

import SEO from "seo/SEO";
import { ExamSchedulePage } from "templates";
import { scrollToTop } from "utils/utilities";

export const ExamSchedule = () => {

  useEffect(() => {
    scrollToTop(window)  
  }, [])

  return (
    <>   
      <SEO
        title="Exam Schedule | Vasudev College of Pharmacy Haldwani"
        description="Vasudev College of Pharmacy is one of the best pharmacy college in Haldwani, Nainital. We offer B.Pharma and D.Pharma courses in Haldwani, Nainital."
        currentURL={`${process.env.REACT_APP_PUBLIC_URL}/student/exam-schedule`}
        keywords="Vasudev College of Pharmacy, Pharmacy College in Haldwani, Pharmacy College in Nainital, B.Pharma College in Haldwani, B.Pharma College in Nainital, D.Pharma College in Haldwani, D.Pharma College in Nainital"
      />   
      <ExamSchedulePage/>      
    </>
  );
};

export default ExamSchedule;