import React from "react";

import { Box } from "atoms";
import { Footer, Header } from "organisms";

export const Layout = ({children}) => {
  return (
    <Box maxWidth="100vw" overflow="hidden">
      <Header/>
      <Box as="main">
        {children}
      </Box>   
      <Footer/>
    </Box>
  );
};
