import { combineReducers } from "redux";

import { appReducer } from "./app.reducer";
import { dataReducer } from "./data.reducer";

const rootReducer = combineReducers({
  app: appReducer,    
  data: dataReducer
})

export default rootReducer