import React from "react";
import { useNavigate } from "react-router-dom";
import { HashLink } from "react-router-hash-link";

import { Text } from "atoms";

export const GetLink = ({
  url,
  children,
  onClick,
  header,
  type  
}) => {

  const navigate = useNavigate();

  const doNavigation = (url) => {
    const isExternal = url?.startsWith("http") || url?.startsWith("www");
    const isHash = url?.startsWith("#");
    const isInternal = url?.startsWith("/");

    if(isExternal) window.open(url, "_blank");
    if(isHash || isInternal){
      if(header) if(onClick) onClick();
      navigate(url);      
    }
  }

  return (
    <> 
      {type === "hash" ? <HashLink
        onClick={() => {
          if(header) if(onClick) onClick();
        }}
        to={url}
        style={{
          textDecoration: "none",            
        }}        
        scroll={(el) => el.scrollIntoView({ behavior: "smooth", block: "start", inline: "nearest" })}
      >
        {children}
      </HashLink> : <Text
        onClick={() => doNavigation(url)}
        style={{
          cursor: "pointer"
        }}
        as="span"
      >
        {children}
      </Text>}    
      {/* if starts with / */}
      {/* {(url?.startsWith("/") || url?.startsWith("#")) && (
        <Link
          to={url}
          style={{
            textDecoration: "none",            
          }}          
        >
          {children}
        </Link>
      )} */}

      {/* if starts with http or www */}
      {/* {(url?.startsWith("http") || url?.startsWith("www")) && (
        <a
          href={url}
          target="_blank"
          rel="noreferrer"
          style={{
            textDecoration: "none"
          }}          
        >
          {children}
        </a>
      )} */}
    </>
  )
}