import React from "react";

import { Box, Image, Text } from "atoms";
import { GetLink } from "molecules";

export const EventCard = ({  
  image,
  date,
  title,  
  slug
}) => {  
  return (
    <Box
      p={{xs: "0.6rem", md: "1.2rem"}}
    >
      <Box
        width="100%"
        height={{xs: "34rem", md: "40rem"}}        
      >
        <Image
          src={image}
          alt={title}
          width="100%"
          height="100%"
          objectFit="cover"
        />
        <Box
          position="absolute"
          bg="white"
          zIndex="2"
          boxShadow="0 0 20px 3px rgba(0, 0, 0, 0.05)"
          borderRadius="0.5rem"
          p="1.5rem 2.5rem"
          left="3rem"
          bottom="-3rem"
        >
          <Text
            as="p"
            fontWeight="600"
            color="secondary.500"            
          >
            {date[0] || " "}            
          </Text>
          <Text
            as="p"
            color="#707070"
            fontWeight="600"
          >
            {date[1] || " "}
          </Text>
        </Box>
      </Box>
      <Box
        p="3rem"
        boxShadow="0 0 20px 3px rgba(0, 0, 0, 0.05)"
      >
        <Box
          width="fit-content"
        >
          <GetLink
            url={`/event/${slug}`}
          >
            <Text
              fontSize={{xs: "2rem", md: "2.2rem"}}
              fontWeight="800"
              color="primary.500"      
              textTransform="capitalize"    
              mb="0.8rem"
              _hover={{
                color: "secondary.500"
              }}              
            >
              {title}
            </Text>  
          </GetLink>  
        </Box>    
      </Box>
    </Box>
  )
}
