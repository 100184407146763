import React from "react"

import { Box, Flex, Image, Text } from "atoms"

export const Message = ({
  id,
  title,
  message,
  image,
  name,
  wish,
  imgFirst=true
}) => {
  return (
    <Box
      id={id}
      p="6rem 3rem"
      maxWidth={{xs: "100%", lg: "120rem"}}
      mx="auto"
    >
      {title && <Text
        as="h2"
        fontSize={{xs: "3rem", md: "3.6rem"}}
        fontWeight="800"
        mb="3rem"
        color="primary.500"
      >
        {title}
      </Text>}
      <Flex
        style={{ gap: "2rem" }}
        flexDirection={{xs: "column", xm: imgFirst ? "row" : "row-reverse"}}
      >
        {image && <Box>
          <Box
            height={{xs: "36rem", md: "60rem", xm: "30rem"}}            
            minWidth="28rem"
            borderRadius="1rem 1rem 0 0"       
            overflow="hidden"
          >
            <Image
              src={image}
              alt={name}
              width="100%"
              height="100%"              
              objectFit="cover"
            />
          </Box>
          <Text
            as="h3"
            fontSize="2.2rem"
            fontWeight="800"
            borderRadius="0 0 1rem 1rem"       
            p="2rem"
            bg="secondary.500"
            color="white"
            textAlign="center"
          >
            {name}
          </Text>
        </Box>}
        <Box>                      
          {message?.map((item, index) => (
            <Text
              key={index}
              as="p"
              fontSize="1.8rem"
              fontWeight="500"              
              color="#707070"
              mb="1.5rem"
              lineHeight="150%"
              display="inline-block"
              verticalAlign="middle"
              className="text-justify"           
            >    
              {index === 0 && <Text
                as="span" 
                fontSize="2.4rem"
                color="secondary.500"
              >&#x201C;</Text>}                      
              {item} 
              {index === message.length - 1 && <Text
                as="span" 
                fontSize="2.4rem"
                color="secondary.500"
              >&#x201D;</Text>}             
            </Text>
          ))} 
          {wish && <Text
            as="p"
            fontSize="1.8rem"
            fontWeight="700"              
            color="accent.100"
            mb="1.5rem"
            textAlign="right"
          >
            {wish}
          </Text>}
        </Box>
      </Flex>
    </Box>
  )
}