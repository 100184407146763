import React from "react";
import { useSelector } from "react-redux";

import { Box, Flex, Text } from "atoms";
import { PageHero } from "molecules";

const data = [
  {
    title: "Privacy Policy",
    values: [
      "Please read this Privacy Policy carefully before using this website.",    
    ],
  },
  {
    title: "Privacy Policy Consent",
    values: [
      "The website and its content is owned by Vasudev College of Pharmacy Haldwani(“Company”, “we”, or “us”). The term “you” refers to the user or viewer of our website. Please read this Privacy Policy carefully. We reserve the right to change this Privacy Policy on the website at any time without notice. Use of any information or contribution that you provide to us, or which is collected by us on or through our website or its content is governed by this Privacy Policy. By using our website or its content, you consent to this Privacy Policy, whether or not you have read it. If you do not agree with this Privacy Policy, please do not use our website or its content.",      
    ],
  },
  {
    title: "Personal Information:",
    values: [
      'We may collect personal information from you such as your name or email address. For example, you may voluntarily submit information to the website by leaving a comment, subscribing to a newsletter, or submitting a contact form. In addition, we may ask you to create a user profile, which would allow you to create a username and password. We will store the username, but your password will not be visible in our records.',
    ],
  },
  {
    title: "Automatically Collected Information:",
    values: [
      'We automatically collect certain information about you and the device with which you access the website. For example, when you use the website, we will log your IP address, operating system type, browser type, referring website, pages you viewed, and the dates/times when you accessed the website. We may also collect information about actions you take when using the website, such as links clicked.',
    ],
  },
  {
    title: "Security",
    values: [
      'We take reasonable measures to protect your personal information from unauthorized access, alteration, disclosure, or destruction.'
    ],
  },  
  {
    title: "Changes to This Policy",
    values: [
      'We may update our Privacy Policy from time to time. The updated version will be indicated by an updated "Last Updated" date. By continuing to access or use our Services after those changes become effective, you consent to the revised Privacy Policy.'
    ],
  }
]

export const PrivacyPolicyPage = () => {

  const { footer } = useSelector(state => state.app);

  return (
    <>
      <PageHero
        image="/assets/page-hero.jpg"
        title="Privacy Policy"
        breadcrumbs={[
          {
            title: "Home",
            url: "/"
          },
          {
            title: "Privacy Policy",
            url: "/privacy-policy"
          }
        ]}
      />
      <Box
        bg="white"                
        p={{xs: "8rem 3rem", lg: "10rem 3rem"}}
        as="section"
        maxWidth={{xs: "100%", lg: "120rem"}}
        mx="auto"
      >
        {data?.map((item, index) => (
          <PopulateTitleValue
            key={index}
            title={item.title}
            values={item.values}
          />
        ))}
        <PopulateTitleValue          
          title="Contact Us"
          values={[
            `If you have any questions or concerns about this  Privacy Policy, please contact us at ${footer?.email?.split("<br/>")?.map((item) => <>{item}{" "}</>)}`,
            "By using our website, you agree to the terms outlined in this Privacy Policy."
          ]}
        />
      </Box>
    </>
  )
}

const PopulateTitleValue = ({ title, values, type="paragraph" }) => (
  <Box
    mb="3rem"
  >
    {title && <Text
      as="h3"
      fontSize={{xs: "2.2rem", md: "2.4rem"}}
      fontWeight="800"
      color="primary.500"
      mb="1rem"
    >
      {title}
    </Text>}    
    {values?.map((value, index) => (
      <>
        {type === "list" && <Flex
          alignItems="center" 
          mb="1.5rem"         
        >
          <Box
            width="1rem"
            height="1rem"
            bg="secondary.500"
            borderRadius="50%"
            mr="1rem"            
          />
          <Text      
            key={index}
            as="p"
            fontSize="1.6rem"
            fontWeight="600"
            color="#707070"
          >
            {value}
          </Text>
        </Flex>}
        {type === "paragraph" && <Text      
          key={index}
          as="p"
          fontSize="1.6rem"
          fontWeight="600"
          mb="1.5rem"
          color="#707070"
          className="text-justify"           
        >
          {value}
        </Text>}
      </>     
    ))}
  </Box>
)