import React, { useEffect } from "react";
import { useDispatch } from "react-redux";

import { getAboutPage } from "redux/actions";
import SEO from "seo/SEO";
import { AboutUsPage } from "templates";
import { scrollToTop } from "utils/utilities";

const AboutUs = () => {

  const dispatch = useDispatch();

  useEffect(() => {
    scrollToTop(window);
    dispatch(getAboutPage());
  }, [dispatch])

  return (
    <>
      <SEO
        title="About Us | Vasudev College of Pharmacy Haldwani"
        description="Vasudev College of Pharmacy is one of the best pharmacy college in Haldwani, Nainital. We offer B.Pharma and D.Pharma courses in Haldwani, Nainital."
        currentURL={`${process.env.REACT_APP_PUBLIC_URL}/about-us`}
        keywords="Vasudev College of Pharmacy, Pharmacy College in Haldwani, Pharmacy College in Nainital, B.Pharma College in Haldwani, B.Pharma College in Nainital, D.Pharma College in Haldwani, D.Pharma College in Nainital"        
      />
      <AboutUsPage/>
    </>
  )
}

export default AboutUs;