import React, { Suspense, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Routes, Route } from "react-router-dom";
import { toast } from "react-toastify";

import { Loader } from "atoms";
import Home from "pages/home";
import ContactUs from "pages/contact-us";
import AboutUs from "pages/about-us";
import Events from "pages/events";
import EventDetails from "pages/event-details";
import Courses from "pages/courses";
import CourseDetails from "pages/course-details";
import Gallery from "pages/gallery";
import GalleryDetails from "pages/gallery-details";
import Syllabus from "pages/syllabus";
import Results from "pages/results";
import ExamSchedule from "pages/exam-schedule";
import Error from "pages/error";
import TermsAndConditions from "pages/terms-and-conditions";
import PrivacyPolicy from "pages/privacy-policy";
import { appConstants, dataConstants } from "redux/constants";
import FacultyStaff from "pages/faculty-staff";

export const AppRoutes = () => {  

  const dispatch = useDispatch();
  const { loading, error, message } = useSelector((state) => state.app);
  const { loading: dataLoading, error: dataError, message: dataMessage } = useSelector((state) => state.data);

  useEffect(() => {
    if(message){
      toast.success(message);
      dispatch({ type: appConstants.RESET_APP });
    }
    if(error){
      toast.error(error);
      dispatch({ type: appConstants.RESET_APP });
    }    
    if(dataMessage){
      toast.success(dataMessage);
      dispatch({ type: dataConstants.RESET_DATA });
    }
    if(dataError){
      toast.error(dataError);
      dispatch({ type: dataConstants.RESET_DATA });
    }
  }, [message, error, dispatch, dataMessage, dataError]);

  return (
    <Suspense fallback={<Loader />}>
      {(loading || dataLoading) && <Loader />}
      <Routes>
        <Route path="/" exact element={<Home/>} />
        <Route path="/about-us" exact element={<AboutUs/>} />
        <Route path="/events" exact element={<Events/>} />
        <Route path="/event/:slug" exact element={<EventDetails/>} />
        <Route path="/courses" exact element={<Courses/>} />
        <Route path="/course/:slug" exact element={<CourseDetails/>} />
        <Route path="/albums" exact element={<Gallery/>} />
        <Route path="/album/:slug" exact element={<GalleryDetails/>} />
        <Route path="/contact-us" exact element={<ContactUs/>} />
        <Route path="/student/syllabus" exact element={<Syllabus/>} />
        <Route path="/student/results" exact element={<Results/>} />
        <Route path="/student/exam-schedule" exact element={<ExamSchedule/>} />        
        <Route path="/terms-and-conditions" exact element={<TermsAndConditions/>} />        
        <Route path="/privacy-policy" exact element={<PrivacyPolicy/>} />        
        <Route path="/faculty/:type" exact element={<FacultyStaff />} />
        <Route path="*" element={<Error/>} />
      </Routes>
    </Suspense>
  );
};